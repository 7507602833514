import React ,{useLayoutEffect} from 'react'

import Header from '../Header'
import Footer from '../Footer'

const AboutUs = ()=>{
    
    useLayoutEffect(()=>{
        window.scrollTo({top:0})
    },[0])

    return(
        <React.Fragment>
            <Header/>
            <div style={{marginBottom:'40px'}}>.</div>

            <div className='main-container' style={{padding:'10px 20px'}}>
                <h1>Welcome to Venamal</h1>
                    <h2>Passion & Purpose</h2>
                        <p>
                        At Venamal, we're fueled by a deep passion for supplying all round AFRICA products/goods.<br/>
                         We believe in the power of having quality goods to ease your day-today lifes. <br/>
                         That's why we've built a platform dedicated to providing you with quality and efficient products that will elevate your standards.<br/>

                        </p>

                    <h2>Our Story </h2>
                        <p>
                            We started small by selling local products easily available in our area, then w migrated to the shipping of more rare external products<br/> 
                            Currently our vision to become the africa leading market place platform suppling products all round Africa.
                        </p>

                    <h2> What Makes Us Different</h2>
                        <p>
                        We stand out because of our unwavering commitment to customer satisfaction.<br/> Whether it's a cheap or expensive product, we strive to meet customer expectances.<br/>
                        Additionally, we prioritize the promotion of locally manufactures products, ensuring you have a home/local experience every time.<br/>
                        </p>

                    <h2>The Venamal Team</h2>

                        <p>
                        We're a passionate group of individuals driven by a love for improvement and a commitment to exceptional customer service. Our team brings a wealth of experience in design, marketing, deveopment of quality softwares, all working together to bring you the best possible experience at Venamal.
                        </p>
            </div>

            <Footer/>
        </React.Fragment>
    )
}

export default AboutUs