import React from 'react'
import { Outlet } from 'react-router-dom'

import '../../assets/styles/auths-styles.css'
import Header from '../Header'

const AuthLayout =()=>{
    return(
        <React.Fragment>

            <div className='auth-layout'>

                <div className='auth-container'>
                    <Outlet/>
                </div>

            </div>
        </React.Fragment>
    )
}

export default AuthLayout