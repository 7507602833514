import React ,{useState} from 'react'
import {
    MDBBtn ,MDBBtnGroup ,MDBInput ,MDBInputGroup
} from 'mdb-react-ui-kit'
import { useNavigate } from 'react-router-dom'

import { HOST_NAME } from '../../config'

const Register =()=>{

    const navigate = useNavigate()
    const [error ,setError] = useState('')
    const [loading ,setLoading] = useState(false)
    const [data ,setData] = useState({
        name:'',
        email:'',
        password:'',
        confirm:''
    })

    const submitForm=async()=>{
            let {name ,email,password ,confirm} = data
        if(!email || !password || !password || !confirm || !name){
            setError("Please fill the entire form")
            return
        }
        try{
            setError('')
            setLoading(true)
            const response = await fetch(`${HOST_NAME}/api/auths/register` ,{
                method:'post',
                headers:{
                    'content-type':'application/json'
                },
                body:JSON.stringify(data)
            })
            const result = await response.json()
            // console.log(response)
            // console.log(result)
            if(response.ok){
                
                navigate("/auths/email-validation" ,{state:{email:email}})
            }else{
                setError(result.error)
            }

        }
        catch(e){
            // console.log(e)
            setError('Please verify your internet')
        }
        finally{
            setLoading(false)
        }
    }


    return(
        <React.Fragment>
            <div>
                <span style={{cursor:'pointer'}} onClick={()=>navigate(-1)}> <i className='fas fa-arrow-left'></i> back </span>
                <h3>Sign Up</h3>
            </div>
            <div className='form'>

            <MDBInputGroup className='form-group'>
                    <span>
                        Name
                        <i className='fas fa-user'></i>
                    </span> 
                        <MDBInput 
                            type='text'
                            value={data.name}
                            onChange={(e)=>setData({...data ,name:e.target.value})}
                        />
                    
                </MDBInputGroup>

                <MDBInputGroup className='form-group'>
                    <span>
                        Email
                        <i className='fas fa-envelope'></i>
                    </span> 
                        <MDBInput 
                            type='email'
                            value={data.email}
                            onChange={(e)=>setData({...data ,email:e.target.value})}
                        />
                    
                </MDBInputGroup>

                <MDBInputGroup className='form-group'>
                    <span>
                        Password
                        <i className='fas fa-lock'></i>
                    </span>
                        <MDBInput 
                            type='password'
                            value={data.password}
                            onChange={(e)=>setData({...data ,password:e.target.value})}
                        />
                </MDBInputGroup>

                <MDBInputGroup className='form-group'>
                    <span>
                        Confirm Password
                        <i className='fas fa-lock'></i>
                    </span>
                        <MDBInput 
                            type='password'
                            value={data.confirm}
                            onChange={(e)=>setData({...data ,confirm:e.target.value})}
                        />
                </MDBInputGroup>

                <span style={{color:'crimson' ,textAlign:'center' ,fontSize:'small' }}>{ error }</span>

                <MDBBtnGroup onClick={()=>submitForm()}>
                    <MDBBtn>Sign Up {loading ? "...":""}</MDBBtn>
                </MDBBtnGroup>

                <div className='auth-option'>
                    <span onClick={()=>navigate("/auths/login")} >Already have an account</span>
                </div>


            </div>
        </React.Fragment>
    )
}

export default Register