import React ,{useState} from 'react'
import {
    MDBInput,
    MDBInputGroup,
    MDBBtn,
    MDBBtnGroup
    
} from 'mdb-react-ui-kit'
import { useNavigate } from 'react-router-dom'
import { HOST_NAME } from '../../config'


const Login =()=>{

    const navigate = useNavigate()
    const [email ,setEmail] = useState('')
    const [password ,setPassword] = useState('')
    const [error ,setError] = useState('')
    const [loading ,setLoading] = useState(false)


    const submitForm=async(e)=>{
        e.preventDefault()
        if(!email || !password){
            setError("Please fill the entire form")
            return
        }
        try{
            setError('')
            setLoading(true)
            const response = await fetch(`${HOST_NAME}/api/auths/login` ,{
                method:'post',
                headers:{
                    'content-type':'application/json'
                },
                body:JSON.stringify({email:email ,password:password})
            })
            const data = await response.json()
            // console.log(data)
            if(response.ok){
                await localStorage.setItem("user" ,JSON.stringify(data.user))
                // return
                if(data.user && data.user.store){
                    navigate("/store")
                }else{
                    navigate("/create-store")
                }
            }else{
                setError(data.error)
            }

        }
        catch(e){
            // console.log(e)
            setError('Please verify your internet')
        }
        finally{
            setLoading(false)
        }
    }

    return(
        <React.Fragment>
            <div>
            <span style={{cursor:'pointer'}} onClick={()=>navigate(-1)}> <i className='fas fa-arrow-left'></i> back </span>
                <h3>Sign In</h3>
            </div>
            <form onSubmit={e=>submitForm(e)} >
            <div className='form'>
                
                <MDBInputGroup className='form-group'>
                    <span>
                        Email
                        <i className='fas fa-envelope'></i>
                    </span> 
                        <MDBInput 
                            type='email'
                            value={email}
                            onChange={(e)=>setEmail(e.target.value)}
                        />
                    
                </MDBInputGroup>

                <MDBInputGroup className='form-group'>
                    <span>
                        Password
                        <i className='fas fa-lock'></i>
                    </span>
                        <MDBInput 
                            type='password'
                            value={password}
                            onChange={(e)=>setPassword(e.target.value)}
                        />
                </MDBInputGroup>

                <span style={{color:'crimson' ,textAlign:'center' ,fontSize:'small' }}>{ error }</span>

                <MDBBtnGroup>
                    <MDBBtn>Sign In {loading ? "...":""}</MDBBtn>
                </MDBBtnGroup>

                <div className='auth-option'>
                    {/* <span>Forgot password ?</span> */}
                    {/* <span onClick={()=>navigate("/auths/register")} >No account ?</span> */}
                </div>

            </div>
            </form>
        </React.Fragment>
    )
}

export default Login