import React ,{useEffect, useState ,useLayoutEffect} from 'react'
import { useParams ,useNavigate, Link } from 'react-router-dom'
import { HOST_NAME } from '../config'

const LinkDetail = () => {
    const params = useParams()
    const navigate = useNavigate()
    const [loading ,setLoading] = useState(true)

// console.log(params.id)

useLayoutEffect(()=>{
        async function getItem(){
            try{
                const response = await  fetch(`${HOST_NAME}/api/item/get-item-by-id/${params.id}`)
                console.log(`${HOST_NAME}/api/item/item-get-item-by-id/${params.id}`)
                console.log(response)
                if(response.ok){
                    const data = await response.json()
                    console.log(data)
                    if(data.item){
                        navigate("/detail" ,{state:data.item})
                    }
                }
            }
            catch(e){
                console.log(e)
            }
            finally{
                setLoading(false)
            }
        } 
        getItem()
    },[0])

    return(
        <React.Fragment>
            <div style={{border:'solid 0px red' ,position:'absolute' ,top:'30%' ,left:'50%' ,transform:'translateX(-50%)'}}>
                <h2 style={{textAlign:'center'}}>
                    404 <br/> Product Not Available
                </h2>
                <center onClick={()=>navigate("/")} style={{cursor:'pointer' ,color:'blue' }}> <i className='fas fa-arrow-left'></i> home page <i className='fas fa-home'></i> </center>
            </div>
        </React.Fragment>
    )
}


export default LinkDetail