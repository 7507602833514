import React, { useLayoutEffect }  from 'react'
import { Outlet ,useNavigate } from 'react-router-dom'
import {MDBBtn ,MDBBtnGroup} from 'mdb-react-ui-kit'

import StoreHeader from './StoreHeader'

import '../../assets/styles/main-styles.css'
import Header from '../Header'

const StoreLayout = () =>{
    
    const navigate = useNavigate()
    const user = JSON.parse(localStorage.getItem("user"))
    // console.log(user)

    useLayoutEffect(()=>{
        if(!user.store){
            navigate("/create-store")
        }
    },[0])

    if(user){
        if(user.store){
            return(
                <React.Fragment>
                    <Header store={user && user.store ? user.store:{}}/>
                    <div style={{marginBottom:'40px'}}>.</div>

                    {/* <StoreHeader store={user.store}/> */}
                        <div className='main-container'>
        
                            <Outlet  context={user}/>
        
                        </div>
                </React.Fragment>
            )
        }else{
            navigate("/create-store")
        }
    }
    else{
        window.location.pathname = "/"
    }
}

export default StoreLayout