import React ,{useEffect ,useState} from 'react'
import { HOST_NAME } from '../../config'
import { MDBTable ,MDBTableBody ,MDBRow ,MDBCol ,MDBTableHead } from 'mdb-react-ui-kit'


const ViewMessages =()=>{

    const [messages ,setMessages] = useState([])
    const [loading ,setLoading] = useState()
    const [error, setError] = useState()
    // const [msg ,setMsg] = useState()

useEffect(()=>{

    async function getMessages(){
        setLoading(true)
        setError("")
        try{
            const response = await fetch(`${HOST_NAME}/api/message/get-messages`)
            const data = await response.json()
            // console.log(data)
            if(response.ok){
                // console.log(data.messages)
                setMessages(data.messages)
            }else{
                setError(data.error)
            }
        }
        catch(e){
            // console.log(e)
            setError("Please verify your internet")
        }
        finally{
            setLoading(false)
        }
    }
    getMessages()
},[0])


    return(
        <React.Fragment>

        <div className='main-container'>
        {!loading && messages.length != 0 &&
            <MDBTable border={"solid 1px grey"} responsive >
                <MDBTableHead dark>
                    <MDBRow>
                        <MDBCol>Email</MDBCol>
                        <MDBCol>Message</MDBCol>
                    </MDBRow>
                </MDBTableHead>
                <MDBTableBody>
                    {messages.map((message ,index) => {
                        return <TableRow key={message._id} message={message} />
                    })}
                </MDBTableBody>
            </MDBTable>
        }

            <span>{error}</span>
        </div>

        </React.Fragment>
    )
}


export default ViewMessages


const TableRow =({message})=>{
    return(
        <MDBRow>
            <MDBCol>{message.email}</MDBCol>
            <MDBCol>{message.message}</MDBCol>

        </MDBRow>
    )
}