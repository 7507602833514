import React ,{useLayoutEffect, useState} from "react";
import {  useNavigate,useLocation  ,useOutletContext} from "react-router-dom";
import {
        MDBInput ,MDBInputGroup ,MDBBtnGroup ,MDBBtn ,MDBFile,MDBTextArea,
        MDBDropdown ,MDBDropdownItem ,MDBDropdownMenu ,MDBDropdownToggle, MDBRadio
} from 'mdb-react-ui-kit'

import '../../assets/styles/auths-styles.css'
import { HOST_NAME } from "../../config";

const ManageService = () => {

    const navigate = useNavigate()
    const location = useLocation()
    const user = useOutletContext()

    const [error ,setError] = useState('')
    const [loading ,setLoading] = useState(false)
    const [files ,setFiles] = useState([])
    const [images ,setImages] = useState([])
    const [data ,setData] = useState({
        name:'',
        description:'',
        contact1:'',
        contact2:'',
        email:''
    })
    const [title ,setTitle] = useState("Add")

useLayoutEffect(()=>{
    if(location.state && location.state.service){
        setTitle("Update")
        setData(location.state.service)
    }
},[0])

    async function handleImageChange(e){
        setFiles([])
        setImages([])
        let temp = e.target.files
        // console.log(e.target.files)   
        setFiles(e.target.files)

        for(let i=0;i<temp.length;i++){
            const reader = new FileReader()
             reader.onload=async()=>{
                setImages(prev => [...prev ,reader.result])
                // console.log(images)

            }
            reader.readAsDataURL(temp[i])
        }

    }

    function cancel(){
        setImages([])
        setFiles([])
        setData({
            name:'',
            caontact:'',
            description:''
        })
        navigate("/store")
    }


const submitForm=async()=>{
    let {name ,description ,contact} = data
    // console.log(data)
    // return
    if(!name || !contact){
        setError("Please fill the require fields")
        return
    }
    setLoading(true)
    setError('')

    const formData = new FormData()
    for(let i=0;i<files.length;i++){
        formData.append("files" ,files[i])
    }
    formData.append("name" ,name)
    // formData.append("price" ,price)

    formData.append("description" ,description)
    formData.append("contact" ,contact)
    // formData.append("quantity" ,quantity)

    // formData.append("fastDeal" ,fastDeal)
    // formData.append("duration" ,duration)

    formData.append("store" ,user.store._id)
    
    let body = {
        name:name ,
        // price:price,
        // description:description,
        contact:contact,
        // quantity:quantity,
        id:data._id,
        // fastDeal:fastDeal,
        // duration:duration
    }

    // console.log(body)
    // return
    try{
        let url = `${HOST_NAME}/api/service/create-service`
        if(title == "Update"){
            url = `${HOST_NAME}/api/service/update-service`
        }

            let response
        if(title == "Update"){
             response = await fetch(url ,{
                method:  "put",
                headers:{
                    'content-type':'application/json',
                    'authorization':`Bearer ${user.token}`
                },
                body: JSON.stringify(body)
            })
        }else{
             response = await fetch(url ,{
                method: "post",
                headers:{
                    // 'content-type':'application/json',
                    'authorization':`Bearer ${user.token}`
                },
                body:formData
            })
        }

        const result = await response.json()
        if(response.ok){
            // console.log(result)
            navigate("/store" ,{state:'fetch'})
        }else{
            setError(result.error)
        }
    }
    catch(e){
        // console.log(e)
        setError("An error occured")
    }
    finally{
        setLoading(false)
    }
}

    return(
        <React.Fragment>

            <div className="auth-container" style={{marginTop:'15px'}}>
                <h3> <span style={{fontSize:'small' ,fontWeight:'bold' ,cursor:'pointer'}} onClick={()=>navigate("/store")}><i className='fas fa-arrow-left'></i> back <br/> </span>
                 {title} Service</h3>
                <div className="form">

                    <MDBInputGroup className="form-group">
                        <span>Name</span>
                        <MDBInput 
                            value={data.name}
                            type="text"
                            onChange={(e)=>setData({...data ,name:e.target.value})}
                        />
                    </MDBInputGroup>

                    <MDBInputGroup className="form-group">
                        <span>Email</span>
                        <MDBInput 
                            value={data.email}
                            type="email"
                            onChange={(e)=>setData({...data ,email:e.target.value})}
                        />
                    </MDBInputGroup>


                    <MDBInputGroup>

                        <MDBInputGroup className="form-group">
                            <span>Phone 1</span>
                            <MDBInput 
                                value={data.contact1}
                                type="number"
                                onChange={(e)=>setData({...data ,contact1:e.target.value})}
                            />
                        </MDBInputGroup>

                        <MDBInputGroup className="form-group">
                            <span>Phone 2(optional)</span>
                            <MDBInput 
                                value={data.contact2}
                                type="number"
                                onChange={(e)=>setData({...data ,contact2:e.target.value})}
                            />
                        </MDBInputGroup>

                    </MDBInputGroup>


                    <MDBInputGroup className="form-group">
                        <span>Description (optional)</span>
                     
                        <MDBTextArea
                            onChange={(e)=>setData({...data ,description:e.target.value})}
                            textarea={true}
                            value={data.description}
                        />
                    </MDBInputGroup>


                    {title != "Update" ? 
                    <label htmlFor="images" style={{cursor:'pointer'}}>Select image(s) <i className="fas fa-camera"></i> <span style={{fontSize:'small'}}>(click here)</span></label>
                    :''
                    }
                    <MDBFile multiple id="images"  itemType="image" style={{display:'none'}} onChange={(e)=>handleImageChange(e)}/>
                    <div style={{border:'solid 0px red',height:images.length > 0 ? '70px':'0px' ,overflowY:'hidden' ,overflowX:'auto' ,
                    display:'flex' ,gap:'5px' ,scrollbarWidth:'0px' ,width:'300px' }}>
                        {
                            images.length > 0 &&
                            images.map((image ,index) => {
                            return  <img src={image} key={index} style={{width:'70px' ,height:'70px'}}/>
                            })
                        }
                    </div>

                    <span style={{color:'crimson' ,textAlign:'center' ,fontSize:'small' }}>{ error }</span>

                    <MDBBtnGroup onClick={()=>submitForm()}>
                        <MDBBtn  color="dark" onClick={()=>cancel()} disabled={loading}>Cancel</MDBBtn>
                        <MDBBtn disabled={loading}>{title == "Update" ? "Update":'Create'} Service {loading && '...'}</MDBBtn>
                    </MDBBtnGroup>

                </div>

            </div>
        </React.Fragment>
    )
}

export default ManageService
