import React ,{useState} from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'

const Share = ({id}) =>{

    const [copy ,setCopy] = useState(false)


function handleCopy(){
        setCopy(true)
    setTimeout(()=>setCopy(false) ,750)
}

    return(
        <React.Fragment>
            <CopyToClipboard text={`https://venamal.com/detail/${id}`} onCopy={()=>handleCopy()} >
            <span
            // href={`https://chat.Share.com/LgbEf1qU7yEFVb5uBW7AtX`} 
            style={{display:'flex' ,flexDirection:'column' ,cursor:'pointer',alignItems:'center' ,border:'solid 0px red' ,width:'fit-content'}}>
                <img src={require('../../assets/images/share.jpg')} width="50px" height="50px"
                style={{
                    borderRadius:'10px',border:'solid 0px grey' ,boxShadow:'2px 2px 3px grey',
                }}
                />
                <span style={{color:'rgb(100,100,100)' ,fontSize:'small'}}>Copy link</span>
            </span>
            </CopyToClipboard>

                {copy && <ToastMessage message={"Link copied"} />}

        </React.Fragment>
    )
}

export default Share

const ToastMessage = ({message}) => {
    return(
        <>
        <div style={{position:'fixed',bottom:'50px' ,padding:'5px 10px' ,backgroundColor:'rgba(0,0,0,0.8)' ,
        color:'rgb(200,200 ,200)' ,borderRadius:'5px' ,left:'50%' ,transform:'translateX(-50%)'}}>   
            {message}
        </div>
        </>
    )
}