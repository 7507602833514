import React ,{useState ,useEffect ,useContext } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { MDBBtnGroup ,MDBBtn ,MDBInput ,MDBInputGroup,
MDBTable ,MDBTableHead ,MDBTableBody ,MDBRow ,MDBCol ,
MDBModal ,MDBModalContent ,MDBModalHeader ,MDBModalBody ,MDBModalTitle, MDBModalFooter ,
MDBDropdown ,MDBDropdownItem ,MDBDropdownMenu ,MDBDropdownToggle} from 'mdb-react-ui-kit'

import { FormattedMessage } from "react-intl";

import '../assets/styles/main-styles.css'
import '../assets/styles/auths-styles.css'
import { HOST_NAME } from "../config";
import Whatsapp from "./extra/Whatsapp";
import { useMemo } from "react";

const PurchaseItem =() =>{

    const {cart ,addItem ,removeItem} = useOutletContext()
    const navigate = useNavigate()

    const [totalPrice ,setTotalPrice] = useState(false)
    const [payOnline ,setPayOnline] = useState(true)
    const [info ,setInfo] = useState({
        phone:false,
        delivery:null,
        email:'',
        location:'Awae escalier',
        name:''
    })
    const [selectedItem ,setSelectedItem] = useState([])
    const [loading ,setLoading] = useState(false)
    const [error ,setError] = useState('')
    const [message ,setMessage] = useState('')
    const [waiting ,setWaiting] = useState(false)

    const [modal ,setModal] = useState(false)
    const [modalInfo ,setModalInfo] = useState({text:'' ,state:''})

    useEffect(()=>{
    if(cart){
        window.scrollTo({top:0})
    }
    },[cart])


    useEffect(()=>{
        let total = 0
        setSelectedItem(cart.items.map(item => {
            item.selectedQuantity = 1
            total = total + item.price
            return item
        }))
        setTotalPrice(total)
    },[cart ,removeItem])



    // console.log(selectedItem)
let p =0
    let items
    if(selectedItem.length > 0){
        items = selectedItem.map((item) => {
            p+=item.price
            // item.quantity = 1
            return (

                <div style={{display:'flex' ,gap:'5px' ,width:'320px'}} key={item._id}>
                    <div style={{flex:'1'}}>
                        <img src={item.images[0]} style={{ borderRadius:'5px',width:'160px' ,height:'160px' ,objectFit:'cover'}}/>
                    </div>
                    <div style={{display:'flex' ,flexDirection:'column' ,flex:'1' ,gap:'5px'}}>
                        <span>{item.name}</span>
                        <span style={{fontWeight:'bold'}}>{item.price} FCFA</span>
                        <div style={{display:'flex' ,justifyContent:'space-around' ,padding:'0px 40px 0px 0px' }}>
                            <button style={{flex:1 ,borderColor:'rgba(0,0,0,0.2)' ,borderWidth:'1px' ,borderRadius:'5px'}}
                            onClick={(e)=>{
                                return
                                changeQuantity(e ,item._id ,(item.selectedQuantity-1))}}> 
                                <i className="fas fa-minus"></i>
                            </button>
                            <span style={{flex:2 ,textAlign:'center' ,fontSize:'large' ,fontWeight:'bold'}}> {item.selectedQuantity} </span>
                        {/* <MDBInput type="number" value={item.selectedQuantity}  min={1} max={item.quantity ? item.quantity:10} 
                            onChange={e=>changeQuantity(e ,item._id)} style={{flex:3}} /> */}
                            <button style={{flex:1 ,borderColor:'rgba(0,0,0,0.2)' ,borderWidth:'1px' ,borderRadius:'5px'}}
                            onClick={(e)=>{
                                return
                                changeQuantity(e ,item._id ,(item.selectedQuantity+1))}}> 
                                <i className="fas fa-plus"></i>
                            </button>
                        </div>

                        <span style={{cursor:'pointer' ,color:'crimson'}} onClick={()=>removeItem(item)}><i className="fas fa-trash"></i>remove</span>
                    </div>
                </div>
            )
            })
    }
// console.log(info)
    async function changeQuantity(e ,id ,newValue){
        
        let t =0
         setSelectedItem(prev => {
            let temp = prev.map((p ,index)=> {
                let product_quantity = p.quantity ? p.quantity : 5 
                if(p._id == id){
                    if(newValue == 0){
                        p.selectedQuantity = 1
                    }
                    else if(newValue > product_quantity){
                        p.selectedQuantity = product_quantity
                    }else{
                        p.selectedQuantity = newValue                    
                    }
                }

                    let itemPrice = p.price * (p.selectedQuantity)
                t = t + itemPrice
                return p
            })
            return temp
        })
        console.log(t)
        setTotalPrice(prev => prev == t ? prev :t)
        t = 0
    }


    let locations = ["Awae Escalier" ,"Montee Jouvence" ]
    let displayLocation = locations.map((loc ,index) => {
        return (
            <span key={index}>
            <MDBDropdownItem  header  onClick={()=>setInfo({...info ,location:loc})}
            style={{cursor:'pointer'}} >{loc}</MDBDropdownItem>
            <MDBDropdownItem divider/>
            </span>
        )
    })

async function orderItems(){
    if(selectedItem.length == 0){
        return
    }
    if(!info.email || !info.phone){
        setModalInfo({text:'Please fill the entire form' ,state:false})
        setModal(true)
        // setError("Please fill the entire form")
        return
    }

    let body
    let items = selectedItem.map(item => {
        return {id:item._id ,quantity:item.selectedQuantity}
    })
    body = {
        totalPrice :p,
        email:info.email,
        items:items,
        phoneNumber:info.phone,
        online:payOnline,
        location:info.location
    }

    // console.log(body)
    // return

    setLoading(true)
    setError('')
    setMessage('')
    try{
        const url = `${HOST_NAME}/api/transaction/create-transaction`
        const response = await fetch(url ,{
            method:"post",
            headers:{
                'content-type':'application/json'
            },
            body:JSON.stringify(body)
        })
        const data = await response.json()
        // console.log(data)
        if(response.ok){
            setModalInfo({text:data.message ,state:true})
            setModal(true)
            // setMessage(data.message)
            if(info.phone || payOnline){
                // setWaiting(true)
                // setTimeout(()=>{
                    // verifyPaymentStatus(data.reference)
                    // setWaiting(false)
                // } ,20000)
            }else{
                setTimeout(() => {
                    window.location.pathname = "/"
                }, 5000);
            }
        }else{
            setModalInfo({text:data.error ,state:false})
            setModal(true)
            // setError(data.error)
        }
    }
    catch(e){
        // console.log(e)
        setModalInfo({text:"Please verify your internet" ,state:false})
        setModal(true)
        // setError("Please verify your internet")
    }
    finally{
        setLoading(false)
    }
}


    return(
        <React.Fragment>

                <PurchaseModal text={modalInfo.text} state={modalInfo.state} show={modal} setShow={setModal}/>


            <div className="main-container">
                <div style={{padding:'15px'}}>
                    <span style={{cursor:'pointer'}} onClick={()=>navigate(-1)}> <i className='fas fa-arrow-left'></i> back </span>
                    <h3><FormattedMessage id="selected-items" /> ({selectedItem.length}) </h3>
                    <div className="product-container">
                        {selectedItem.length == 0 && 
                        <>
                            <div onClick={()=>navigate('/')} style={{cursor:'pointer'}} title="buy articles"  >
                                <span 
                                style={{border:'solid 1px darkblue' ,padding:'15px 20px' ,color:"darkblue" ,fontSize:'large' ,boxShadow:'2px 2px px grey'}}>
                                    <FormattedMessage id="buy-article" />
                                </span>
                            </div>
                        </>}
                        {/* <MDBTable>
                            <MDBTableHead>
                                <MDBRow style={{fontWeight:'bold'}}>
                                    <MDBCol>Item</MDBCol>
                                    <MDBCol>Price(CFA)</MDBCol>
                                    <MDBCol>Quantity</MDBCol>
                                </MDBRow>
                            </MDBTableHead>
                            <MDBTableBody>
                                
                                {items}

                            </MDBTableBody>
                        </MDBTable> */}
                        {items}
                    </div>

                    <div>
                        <h4><FormattedMessage id="validate-payment" /></h4>
                        <div className="form">
                            <MDBInputGroup>
                                <MDBInputGroup className="form-group">
                                    <span ><FormattedMessage id="total-price" /> (CFA)</span>
                                    <MDBInput type="text" 
                                    value={totalPrice ? totalPrice : p} disabled/>
                                </MDBInputGroup>
                            </MDBInputGroup>

                            <MDBInputGroup>
                                <MDBInputGroup className="form-group">
                                    <span>Email <span style={{fontSize:'small'}}></span></span>
                                    <MDBInput type="email" value={info.email} onChange={e=>setInfo({...info ,email:e.target.value})} />
                                </MDBInputGroup>
                            </MDBInputGroup>

                            <MDBInputGroup>
                                <MDBInputGroup className="form-group">
                                    <span><FormattedMessage id="store-location" /> <span style={{fontSize:'small'}}></span></span>

                                <div style={{display:'flex',columnGap:'5px'}}>
                                    <MDBDropdown >
                                    <MDBDropdownToggle  split style={{ padding:'12px',backgroundColor:'rgba(0,0,0,0.8)'}} >{'Yaounde'}</MDBDropdownToggle>
                                        {/* <MDBDropdownMenu>
                                            <MDBDropdownItem>Yaounde</MDBDropdownItem> 
                                        </MDBDropdownMenu> */}
                                    </MDBDropdown>

                                    <MDBDropdown  >
                                    <MDBDropdownToggle style={{ padding:'12px',backgroundColor:'rgba(0,0,0,0.8)'}} >{info.location}</MDBDropdownToggle>
                                        <MDBDropdownMenu>
                                            {displayLocation}      
                                        </MDBDropdownMenu>

                                    </MDBDropdown>
                                </div>

                                </MDBInputGroup>
                            </MDBInputGroup>

                      
                            <MDBInputGroup className="form-group">
                                <span><FormattedMessage id="payment-method" /> : </span>
                                <MDBInputGroup style={{gap:'20px'}}>
                                    <MDBBtn color={payOnline==true ? 'primary':'dark'} onClick={()=>setPayOnline(true)}><FormattedMessage id="online" /> </MDBBtn>
                                    <MDBBtn color={payOnline==false? 'primary':'dark'} onClick={()=>setPayOnline(false)} ><FormattedMessage id="during-exchange" /> (90%) </MDBBtn>
                                </MDBInputGroup>
                            </MDBInputGroup>
                                {
                                    // payOnline &&
                                        <MDBInputGroup className="form-group">
                                                <span><FormattedMessage id="phone-number" />
                                                <div>
                                                    <img src={require('../assets/images/mtnMoney.jpg')} width="50px" height="50px" />&nbsp;|&nbsp; 
                                                    <img src={require('../assets/images/orangeMoney.jpg')} width="50px" height="50px"  />    
                                                </div>
                                                </span>
                                               {!payOnline && <span style={{color:'crimson'}}>10% garantee : {(10/100 * totalPrice)} FCFA</span>}
                                            <MDBInput type="number" value={info.phone} placeholder="ex : 694124760" onChange={e=>setInfo({...info ,phone:e.target.value})}/>
                                        </MDBInputGroup>
                                }


                            {/* <MDBInputGroup className="form-group">
                                <span >Delivery ? <span style={{fontSize:'small'}}>(only available in <b>Yaounde</b>)</span> </span>
                                <MDBInputGroup style={{gap:'20px'}}>
                                    <MDBBtn color={info.delivery == true ?"primary":'dark'} onClick={()=>setInfo({...info ,delivery:true})}>Yes </MDBBtn>
                                    <MDBBtn color={info.delivery == false ?"primary":'dark'} onClick={()=>setInfo({...info ,delivery:false})}>No </MDBBtn>
                                </MDBInputGroup>

                            </MDBInputGroup> */}

                            {/* {
                                info.delivery &&
                                <MDBInputGroup>
                                    <MDBInputGroup className="form-group">
                                        <span>Quater</span>
                                        <MDBInput type="text" value={info.quater} onChange={(e)=>setInfo({...info ,quater:e.target.value})}/>
                                    </MDBInputGroup>
                                </MDBInputGroup> 

                            } */}

                            <span style={{color:'crimson' ,textAlign:'center' ,fontSize:'small' }}>{ error }</span>
                            <span style={{color:'darkgreen' ,textAlign:'center' ,fontSize:'small' }}>{ message }</span>


                            <MDBBtnGroup onClick={()=>loading || waiting ? '':orderItems()}>
                                <MDBBtn color="success" disabled={loading || waiting}><FormattedMessage id="order" /> {loading ? "...":""}</MDBBtn>
                            </MDBBtnGroup>
                            

                        </div>
                    </div>
                            
                        {/* <div style={{padding:' 20px'}}>
                            <br/>
                            <Whatsapp/>
                        </div> */}

                        <div style={{padding:'0px 20px' ,marginTop:'50px'}}>
                            <h4><FormattedMessage id="caution" /></h4>
                            {/* <div>In case of any meeting between the client and the supplier, <br/> */}
                                <ul>
                                    <li><FormattedMessage id="caution-1" /></li>
                                    <li><FormattedMessage id="caution-2" /></li>
                                    <li><FormattedMessage id="caution-3" /> venamal.contact@gmail.com</li>
                                </ul> 
                            {/* </div> */}
                        </div>

                </div>
            </div>
        </React.Fragment>
    )
}

export default PurchaseItem



const PurchaseModal = ({state ,text ,show ,setShow}) =>{

    const [counter ,setCounter] = useState(20)

    useEffect(()=>{
        if(state){
            startCounter()
        }
    })

    function startCounter(){
        let timer
        if(counter > 0){
            timer = setTimeout(()=>{
                setCounter(counter-1)
            },1000)
        }else{
            clearTimeout(timer)
            window.location.pathname = "/"
        }
    }

    let icon
    let title
    if(state){
        icon = "fas fa-check"
        title = "SUCCESS"

    }else{
        icon = "fas fa-x"
        title = "ERROR"
    }

    return(
        <React.Fragment>
                <div style={{ display:show ? '':'none',position:'absolute' ,zIndex:"100",width:'100%' ,height:'200vh' ,overflowY:'hidden' ,backgroundColor:'rgba(0,0,0,0.4)'}}>
                </div>

            <MDBModal open={show} onClose={()=>setShow(false)} >
                <MDBModalContent style={{top:'30%' ,width:'fit-content',left:'45%' ,transform:'translateX(-50%)' ,
                margin:'5%' ,fontSize:'large' ,fontWeight:'normal'}}>
                    <MDBModalHeader><MDBModalTitle style={{color:state ? 'green':'crimson'}}>
                        <span className={icon} ></span> &nbsp;&nbsp;{title} {state ? `- ${counter}`:''}
                    </MDBModalTitle></MDBModalHeader>
                    <MDBModalBody style={{padding:'20px 20px'}}>
                        {text}
                        
                    </MDBModalBody>

                </MDBModalContent>

            </MDBModal>

        </React.Fragment>
    )
}


