import React,{ useState,useContext ,useEffect} from "react";
import { Outlet ,useNavigate  ,useLocation} from "react-router-dom";

import Header from "./Header";
import Footer from "./Footer";

import CartContext from "../providers/CartProvider";

import '../assets/styles/index-styles.css'

const Index =()=>{

    const {cart ,setCart} = useContext(CartContext)
    const navigate = useNavigate()
    const location = useLocation()

    const [number ,setNumber] = useState(0)
    useEffect(()=>{
        setNumber(cart.items.length)
    },[cart ,setCart])

// Function to add an item in the card
    function addItem(p){
        setCart(prev => {
            let temp = prev
            let exist = false
            let test = []
            test = prev.items.filter(item => item._id == p._id)
            if(test.length != 0){exist = true}
            if(!exist){

                temp.items.push(p)
                // console.log(temp)
                setNumber(temp.items.length)
                return temp
            
            }else{
                return prev
            }
        })
    }

// Function to delete or remove an item from a card
    function removeItem(p){
        // console.log(p)
        setCart(prev => {
            let temp = prev
            let newItems = prev.items.filter(item => item._id != p._id)
            temp.items = newItems
            // console.log(temp)
            setNumber(temp.items.length)
            return temp
        })
    }

    function purchase(){
        // if(cart.items.length != 0){
            navigate("/purchase-item")
        // }
    }


    return(
        <React.Fragment>

            <Header counter={cart.items.length}/>
                    <div style={{marginBottom:'40px'}}>.</div>
                <Outlet context={{cart:cart ,addItem:addItem ,removeItem:removeItem}}/>

            {/* <Footer/> */}

                {/* Shopping card */}
                <span className="cart" onClick={()=>purchase()} style={{display:location.pathname == "/purchase-item" ? 'none':''}}> 
                    <i className="fas fa-cart-shopping"><sup>{cart.items.length}</sup></i>
                    {/* <span style={{fontSize:'xx-small' ,color:"white" ,fontWeight:'bold'}}>basket</span> */}
                </span>
            
        </React.Fragment>
    )
}

export default Index