import React from 'react'
import Header from '../Header'

const Terms = ()=>{
    return(
        <React.Fragment>
            <Header/>
                Terms and privacy
        </React.Fragment>
    )
}

export default Terms