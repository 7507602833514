import React ,{useState ,useLayoutEffect} from 'react'
import { useLocation ,useNavigate } from 'react-router-dom'

import {MDBInput ,MDBInputGroup} from 'mdb-react-ui-kit'

import '../../assets/styles/main-styles.css'
import '../../assets/styles/product-styles.css'
import { HOST_NAME } from '../../config'
import ProductItem from '../ProductItem'
import Header from '../Header'

const ViewProducts = () =>{

    const navigate = useNavigate()
    const location = useLocation()

    const [store ,setStore] = useState(null)
    // const [items ,setItems] = useState([])
    const [error ,setError] = useState('')
    const [loading ,setLoading] = useState(true)
    const [contact ,setContact] = useState('')


useLayoutEffect(()=>{
    async function getItems(){
        let id = location.pathname.split('/')[location.pathname.split('/').length -1]
        // console.log(id)
        // return
        try{
            setLoading(true)
            const response = await fetch(`${HOST_NAME}/api/store/get-store-items/${id}`)
            const data = await response.json()
            // console.log(response)
            if(response.ok){
                // console.log(data)
                setStore(data.store)
                setContact(data.store.contact[0].split('-'))
            }else{
                setError(data.error)
            }
        }
        catch(e){
            // console.log(e)
            setError('Please verify your connection')
        }
        finally{
            setLoading(false)
        }
    }
    getItems()
} ,[0])

let products
if(store && store.items && store.items.length > 0){
    products = store.items.map(item => {
        return <ProductItem item={item} key={item._id} view={true}/>
    })
}
// console.log(store)
// let contact = [0,1]
if(store != null){
    return(
        <React.Fragment>

            <Header/>

                <div className='main-container'>

                    <div style={{padding:'30px 25px 0px 30px'}}>
                        <span style={{cursor:'pointer'}} onClick={()=>navigate(-1)}> <i className='fas fa-arrow-left'></i> back </span>
                        <h3 style={{borderBottom:'solid 1px grey' ,width:'fit-content'}}>{store.name}</h3>
                    </div>

                    <div style={{padding:'0px 10px'}}>
                        <h4>Contact Informations</h4>

                        <MDBInputGroup className='form-group'>
                            <span>Email</span>
                            <MDBInput type="text" value={contact[0]} disabled />
                        </MDBInputGroup>
                        
                       <MDBInputGroup>
                            <MDBInputGroup className='form-group'>
                                <span>Phone 1</span>
                                <MDBInput type="number" value={contact[1]} disabled/>
                            </MDBInputGroup>

                            {
                                contact.length == 3 ?
                                <MDBInputGroup className='form-group'>
                                    <span>Phone 2</span>
                                    <MDBInput type="number" value={contact[2]} disabled/>
                                </MDBInputGroup>:''   
                            }
                       </MDBInputGroup>

                        <MDBInputGroup className='form-group'>
                            <span>Location</span>
                            <MDBInput type='text' value={store.location} disabled/>
                        </MDBInputGroup>

                    </div>

                    <div style={{padding:'30px 0px'}}>
                        <h4 style={{padding:'0px 10px'}}>Items ({store && store.items ? store.items.length:''}) </h4>
                        {
                        loading ?
                        <div style={{textAlign:'center' ,padding:'50px 0px'}}>
                            <span>Loading products ...</span>
                            <span>{error && error}</span>
                        </div>
                        :
                        <div className='product-container'>
                            {products}
                        </div>

                        }
                    </div>
              
                </div>

        </React.Fragment>
    )
}else{
    return(
        <React.Fragment>

            <Header/>
        
            <div className='main-container'>
                <span style={{textAlign:'center' ,padding:'10px'}}>Loading Data...</span>
            </div>

        </React.Fragment>

    )
}
}

export default ViewProducts