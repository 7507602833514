import React ,{useState ,useEffect ,useContext} from 'react'
import ServiceContext from '../providers/ServiceProvider'
import { HOST_NAME } from '../config'

const Services = ()=> {

const [myServices ,setMyServices]= useState([])
const [error ,setError] = useState('')
const storedServices =  useContext(ServiceContext)

useEffect(()=>{
    async function getServices(){
        try{
            const response = await fetch(`${HOST_NAME}/api/service/get-services`)
            const data = await response.json()
            if(response.ok){
                setMyServices(data.services)
                storedServices.setServices(data.services)

            }else{
                setError(data.error)
            }
        }catch(e){
            // console.log(e)
            setError("Please verify your internet connection")
        }
    }

// if(storedServices.services.length == 0){
//     getServices()
// }else{
//     setMyServices(storedServices.services)
// }

},[0])

    return(
        <React.Fragment>
            {/* <div>
            <h3 style={{paddingLeft:'10px' ,marginBottom:'0px' }} >Services</h3>
            </div> */}
        </React.Fragment>
    )
}

export default Services


const ServiceItem=({service})=>{
    return(
        <>
        <div>

        </div>
        </>
    )
}