import React from 'react'

const ForgotPassword =()=>{
    return(
        <React.Fragment>
            Auths ForgotPassword
        </React.Fragment>
    )
}

export default ForgotPassword