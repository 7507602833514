import React ,{useLayoutEffect} from 'react'

import Header from '../Header'
import Footer from '../Footer'

const Help = ()=>{

    useLayoutEffect(()=>{
        window.scrollTo({top:0})
    },[0])

    return(
        <React.Fragment>
            <Header/>
            <div style={{marginBottom:'40px'}}>.</div>

            <div className='main-container' style={{padding:'10px 20px'}}>
                <h1>Any doubt ?</h1>
                <h2>Getting Started</h2>
                <p>
                    Welcome to the Venamal Help Center! Whether you're a seasoned shopper or a curious newcomer, we're here to guide you through every step of your journey on our platform. <br/>
                    This Help Center is designed to answer your most frequently asked questions (FAQs) about browsing, searching, and placing orders.
                </p>


                <h2>Ordering & Payment</h2>
                <p>
                    Finding the perfect item is just the beginning! Here, you'll find detailed information about navigating our checkout process, secure payment options we offer, and any applicable taxes or fees.<br/>
                     We've also included a guide on using any discount codes or promotions you might have.
                </p>

                <h2>Shipping & Returns</h2>
                <p>
                    We understand that getting your order quickly and safely is important.<br/>
                    This section provides clear information on our shipping timelines, costs, and available delivery options.<br/>
                     Additionally, you'll find a comprehensive guide on our return policy, including eligibility criteria and easy-to-follow return instructions.
                </p>

                <h2> Account Management </h2>
                    <p>
                        Your account is your hub for managing your orders, tracking shipments, and keeping your information up-to-date.<br/>
                        This section walks you through creating and managing your account, accessing order history, and updating your account details.
                    </p>

                <h2>Need More Help?</h2>
                <p>
                    If you can't find the answer you're looking for in our Help Center, don't hesitate to reach out! Our friendly customer support team is always happy to assist you. <br/>
                    You can contact us via email : <span style={{fontWeight:'bold'}}> contact@venamal.com</span>.
                </p>


            </div>
            <Footer/>
        </React.Fragment>
    )
}

export default Help