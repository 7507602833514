import React ,{useState ,createContext} from "react";

const ProductsContext = createContext([])

export const ProductsProvider = ({children})=>{

    const [products ,setProducts] = useState([])
    const [storeProducts ,setStoreProducts] = useState([])
    const [total ,setTotal] = useState()
    const [target ,setTarget] = useState(1)

    return(
        <ProductsContext.Provider 
            value={{
                products:products ,setProducts:setProducts ,
                storeProducts:storeProducts ,setStoreProducts:setStoreProducts,
                total:total ,setTotal:setTotal,
                target:target,
                setTarget:setTarget
            }}
        >
                {children}
        </ProductsContext.Provider>

    )
}

export default ProductsContext