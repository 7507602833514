import React ,{useState ,useEffect ,useContext ,useLayoutEffect} from "react";
import { useLocation ,useNavigate ,useOutletContext } from "react-router-dom";
import vid from '../assets/venamal_loading.mp4'
// 'C:\Users\SIMO\Desktop\e_commerce\Frontend\src\assets\venamal_loading.mp4'

import { FormattedMessage } from "react-intl";

import {
    MDBInputGroup ,MDBInput ,MDBBtnGroup ,MDBBtn ,MDBTextArea, MDBRange, MDBProgress, MDBProgressBar
} from 'mdb-react-ui-kit'

import Slider from "./Slider";
import '../assets/styles/main-styles.css'
import '../assets/styles/auths-styles.css'
import { HOST_NAME } from "../config";
import Whatsapp from "./extra/Whatsapp";
import Share from "./extra/Share";

// import CartContext from "./providers/CartProvider";

const Detail =()=>{

    const navigate = useNavigate()
    const location = useLocation()
    const {cart ,addItem ,removeItem} = useOutletContext()

    const [loading ,setLoading] = useState(true)

    const [product ,setProduct] = useState({images:[]})
    const [exist ,setExist] = useState(false)
// console.log(location.state)
    useLayoutEffect(()=>{
        if(location.state){
            setProduct(location.state)
        }else{
            window.location.pathname = "/"
        }
        // if(product){
        //     window.scrollTo({top:0})
        // }
        // window.scrollTo({top:0})
        setTimeout(()=>{
            setLoading(false)
        },500)
    },[product ,location])

    useEffect(()=>{
        let temp = null 
        // console.log(cart.items.length)
        if(cart.items.length > 0){
            temp = cart.items.filter(item => item._id == product._id)
            // console.log(temp)
               if(temp.length > 0){
                   setExist(true)
               }
        }
    },[0])
    // console.log(exist)
// console.log(product)

// let lines =  product.description.split['\n'].length
let lines = 5
if(product.description && product.description.split('\n').length){
    lines = product.description.split('\n').length
}else{
    lines = 0
}
// console.log(product.description.split('\n').length)

let transport = "walking"
if(product.duration > 14 && product.duration < 21 ){transport = "shipping-fast"}
else if(product.duration > 21 && product.duration < 40){transport = "plane"}
else if(product.duration > 40 ){transport = "sailboat"}
// console.log(product)

if(location.state){
    return(
        <React.Fragment>
            
            {
                !loading ?
                    <div className="main-container">
                        {
                            product.images.length != 0 && <Slider images={product.images}/>
                        }

                            {/* Product informations */}
                        <div style={{padding:'0px 20px'}}>
                            <div>
                                <span style={{cursor:'pointer'}} onClick={()=>navigate(-1)}> <i className='fas fa-arrow-left'></i> back </span>
                                <h4><FormattedMessage id="item-info" /> 
                                    {/* {product.store && product.store.accountType == "admin" &&
                                    <i className='fas fa-shield-alt' style={{color:'rgba(0,0,255,0.7)' }}></i>} */}
                                    </h4>
                            </div>

                            <MDBInputGroup style={{display:'flex' ,justifyContent:'space-around'}}>

                                <MDBInputGroup className="form-group"  >
                                    <span><FormattedMessage id="name" /></span>
                                    <MDBInput type="text" value={product.name} disabled/>
                                </MDBInputGroup>

                                <MDBInputGroup className="form-group"  >
                                    <span><FormattedMessage id="price" /></span>
                                    <MDBInput type="number" value={product.price} disabled/>
                                </MDBInputGroup>

                            </MDBInputGroup>

                            <MDBInputGroup style={{display:'flex' }}>

                                <MDBInputGroup className="form-group"  >
                                    <span>Category</span>
                                    <MDBInput type="text"  value={product.category} disabled/>
                                </MDBInputGroup>

                                {/* <MDBInputGroup className="form-group"  >
                                    <span>Quantity</span>
                                    <MDBInput type="text"  value={product.quantity} disabled/>
                                </MDBInputGroup> */}

                            </MDBInputGroup>
                        
                            <MDBInputGroup className="form-group"  >
                                    <span>Description</span>
                                    {/* <MDBInput type="text"  value={product.description} disabled multiple/> */}
                                    <MDBTextArea value={product.description} disabled rows={lines+1} textarea={true} style={{height:'auto' ,resize:'none'}}/>
                            </MDBInputGroup>

                                {
                                    product.store.accountType != "admin" && product.store.location && product.store.location.length != 0 &&

                                    <MDBInputGroup className="form-group">
                                        <span>Supplier Location</span>
                                        <MDBInput value={product.store.location.map(loc => loc)} disabled />
                                    </MDBInputGroup>

                                }

                            <MDBInputGroup className="form-group"  >
                                    <span><FormattedMessage id="availability" /></span>
                                    <div style={{display:'flex' ,width:'100%'  ,padding:'0px 10px' ,columnGap:'10px' ,alignItems:'flex-end'}}>
                                            <b>0</b>
                                            <div style={{display:'flex' ,borderBottom:'solid 2px brown' ,width:'100%' ,alignItems:'center',
                                            paddingBottom:'0px'}}>
                                                <div style={{ border:'solid 1px darkblue',height:'1px' ,width:`${product.duration}%`}}></div>&nbsp;&nbsp;
                                                <div style={{display:'flex' ,width:'fit-content'}}>
                                                    <span className={`fas fa-${transport}`}></span> &nbsp; <b> {product.duration} <FormattedMessage id="days" /></b>
                                                </div>
                                            </div>
                                                <b>100</b>

                                            {/* <div style={{position:'' ,display:'flex',border:'solid 1px green' ,width:'100%' ,
                                            justifyContent:'space-between' ,padding:'0px 20px'}}>
                                                <span className="fas fa-walking"></span>
                                                <span className="fas fa-ship"></span>
                                                <span className="fas fa-plane"></span>

                                            </div> */}
                                    </div>
                                
                            </MDBInputGroup>

                        </div>

                        {
                            product.store && product.store.accountType == "admin" &&

                            <div style={{padding:'0px 20px'}} >
                                <MDBBtnGroup style={{display:'flex' ,gap:'10px'}}>
                                    <MDBBtn onClick={()=>addItem(product)}>
                                        <FormattedMessage id="add" /> <i className="fas fa-cart-shopping"></i><i className="fas fa-plus"></i></MDBBtn>
                                    {/* { */}
                                    <MDBBtn color="danger" onClick={()=>removeItem(product)}>
                                        <FormattedMessage id="remove" /> <i className="fas fa-cart-shopping"></i><i className="fas fa-minus"></i></MDBBtn>

                                    {/* } */}
                                </MDBBtnGroup>
                            </div>
                        }

                            {
                                product.store.accountType == "admin" &&
                                    <div style={{padding:'0px 20px' ,marginTop:'-20px' ,marginBottom:'-30px'}}>
                                        <strong style={{fontSize:'large'}}><FormattedMessage id="any-question" /> &nbsp;<i className="fas fa-circle-question"></i> </strong><br/>
                                    </div>
                            }

                                {/* Extra informations */}
                                <div style={{padding:'0px 20px' ,display:'flex' ,gap:'20px' , alignItems:'baseline'}}>
                                    <div style={{display:'flex' ,flexDirection:'column' ,alignItems:'center' }} >
                                        <Whatsapp/>
                                        {/* <span style={{fontSize:'smaller' ,fontWeight:'bold'}} > Supplier contact </span> */}
                                    </div>
                                    {/* <CopyToClipboard text="simo" onCopy={()=>{}} options={{message:"hello"}} > */}
                                    <div style={{display:'flex' ,flexDirection:'column' ,alignItems:'center' }} >
                                        <Share id={product._id} />
                                        {/* <span style={{fontSize:'smaller' ,fontWeight:'bold'}}  >Link to product</span> */}
                                    </div>
                                    {/* </CopyToClipboard> */}
                                </div>

                            {/* Supplier contact informations */}
                            {/* {
                            product.store && product.store.accountType != "admin" && 
                                <div style={{padding:'0px 20px'}}>
                                <div>
                                    <h4><FormattedMessage id="contact-info" /></h4>
                                </div>

                                <MDBInputGroup style={{display:'flex' ,justifyContent:'space-around'}}>

                                    <MDBInputGroup className="form-group"  >
                                        <span>Tel1</span>
                                        <MDBInput type="text" value={product.store.contact[0].split('-')[1]} disabled/>
                                    </MDBInputGroup>

                                    {
                                        product.store.contact[0].split('-').length>=3 &&
                                            <MDBInputGroup className="form-group"  >
                                                <span>Tel2</span>
                                                <MDBInput type="number" value={product.store.contact[0].split('-')[2]} disabled/>
                                            </MDBInputGroup>
                                    }

                                </MDBInputGroup>

                                </div>
                            } */}


                            {/* Seller informations */}
                            {
                                // product.store && product.store.accountType != "admin" && 
                                //     <div style={{padding:'0px 20px'}}>
                                //         <h4>Store Information <span style={{fontSize:'smaller'}}></span></h4>
                                //         <span style={{textDecoration:'underline' ,color:'darkblue' ,cursor:'pointer'}} 
                                //         onClick={()=>navigate(`/${product.store._id}`)}> <i className="fas fa-hand-point-right"></i> Click to view the store</span>
                                //     </div>
                            }


                            {/* Helpfull advice */}
                        <div style={{padding:'0px 20px'}}>
                            <h4><FormattedMessage id="security-advice" /></h4>
                            <div><FormattedMessage id="sec-1" /> ,<br/>
                                <ul>
                                    <li><FormattedMessage id="sec-6" /> </li>
                                    <li><FormattedMessage id="sec-2" /></li>
                                    <li><FormattedMessage id="sec-3" /></li>
                                    <li><FormattedMessage id="sec-4" /></li>
                                    <li><FormattedMessage id="sec-5" /></li>

                                </ul> 
                            </div>
                        </div>

                            {/* Previous clients Comments*/}
                        <div>
                        
                        </div>

                    </div>
                :
                <div style={{minHeight:'80vh' ,alignItems:'center' ,justifyContent:'center' ,fontSize:'large' ,backgroundColor:'white'}} className="main-container">
                    <img src={require('../assets/images/loading.gif')} width={'100px'}  />

                    {/* <img src={require('../assets/images/venamal-white-icon.png')} width={'100px'} className="custom_loader" /> */}

                    {/* <h2>test</h2> */}
                    {/* <video width={'200px'} height={'50px'} autoPlay >
                        <source src={vid}/>
                    </video> */}
                </div>

            }

        </React.Fragment>
    )
}else{
    window.location.pathname = "/"
}
}

export default Detail