import React ,{useState ,useContext, createContext} from 'react'
import {
    createBrowserRouter,
    createRoutesFromElements,
    RouterProvider,
    Route,
} from 'react-router-dom'

import { IntlProvider } from 'react-intl';
import english from './i18n/en.json'
import french from './i18n/fr.json'

import './assets/fontawesome/css/all.css'
import 'mdb-react-ui-kit/dist/css/mdb.min.css';

import Index from './components/Index';
import Main from './components/Main'
import Detail from './components/Detail'
import PurchaseItem from './components/PurchaseItem';

import LinkDetail from './components/LinkDetail';

// Authentication components
import AuthLayout from './components/auths/AuthLayout';
import Login from './components/auths/Login';
import Register from './components/auths/Register';
import ForgotPassword from './components/auths/ForgotPassword';
import EmailValidation from './components/auths/EmailValidation';

// Store Components
import StoreLayout from './components/store/StoreLayout';
import Dashboard from './components/store/Dashboard';
import CreateStore from './components/store/CreateStore';
import ViewProducts from './components/store/ViewProducts';
import ManageProduct from './components/store/ManageProduct'
import UpdateStore from './components/store/UpdateStore';
import Transactions from './components/store/Transactions';
import ManageService from './components/store/ManageService';
import ViewMessages from './components/store/ViewMessages';

// Info pages
import Help from './components/info/Help';
import AboutUs from './components/info/AboutUs';
import Terms from './components/info/Terms&Privacy';
import ContactInfo from './components/info/ContactInfo';
import MapLocation from './components/info/MapLocation';

//  provider
import { ProductsProvider } from './providers/ProductProvider';
import { CartProvider } from './providers/CartProvider';
import { ServiceProvider } from './providers/ServiceProvider';
// import { LanguageProvider } from './providers/LanguageProvider';

export const LanguageContext = createContext() 
const App =()=>{

    const [language ,setLangauge] = useState('fr')
    const languageObject = language == 'fr' ? french:english

const reload = () => window.location.pathname="/"

    const router = createBrowserRouter(
        createRoutesFromElements(
            <>

            <Route
                path='/'
                Component={Index}
            >
                <Route index Component={Main}/>

                <Route
                    path="/detail"
                    Component={Detail}
                />
                <Route 
                    path="/detail/:id"
                    Component={LinkDetail}
                />

                <Route path="purchase-item" Component={PurchaseItem}/>

            {/* Security routes to be blocked */}
            <Route path="/admin" Component={reload}/>
            <Route path="/ipv" Component={reload}/>
            <Route path="/exe" Component={reload}/>
            <Route path="/cc" Component={reload}/>
            <Route path="/login" Component={reload}/>
            <Route path="/dashboard" Component={reload}/>
            <Route path="/management" Component={reload}/>
            <Route path="/logout" Component={reload}/>
            <Route path="/home" Component={reload}/>

            </Route>

                {/* Authentication related routes */}
            <Route
                path="/auths"
                Component={AuthLayout}
            >
                <Route index path="login" Component={Login}/>
                <Route path="register" Component={Register}/>
                <Route path="forgot-password" Component={ForgotPassword}/>
                {/* <Route path="email-validation" Component={EmailValidation} /> */}

                <Route path="*" Component={Login} />
            </Route>

                {/* Store related routes */}
            <Route path='/create-store' Component={CreateStore}/>
            <Route path=":id" Component={ViewProducts}/>
            <Route
                path="/store"
                Component={StoreLayout}
            >
                <Route index  Component={Dashboard}/>
                <Route path="manage-product" Component={ManageProduct} />
                <Route path="update-store" Component={UpdateStore} />
                <Route path="transactions" Component={Transactions}/>
                <Route path="manage-service" Component={ManageService}/>
                <Route path="view-messages" Component={ViewMessages}/>

            </Route>

                {/* Informative related pages */}
            <Route path="help" Component={Help}/>
            <Route path="about-us" Component={AboutUs}/>
            <Route path="terms" Component={Terms}/>
            <Route path="contact-us" Component={ContactInfo}/>
            <Route path='map-location' Component={MapLocation}/>


            <Route path="*" Component={reload}/>

            </>
        )
    )

    return(
    <React.Fragment>
            <LanguageContext.Provider value={{language:language ,setLanguage:setLangauge}}>
        <IntlProvider locale={language}  messages={languageObject}>

                <ProductsProvider>
                    <CartProvider>
                        <ServiceProvider>
                        
                            <RouterProvider router={router} />

                        </ServiceProvider>
                    </CartProvider>
                </ProductsProvider>

        </IntlProvider>
            </LanguageContext.Provider>
    </React.Fragment>
    )
}

export default App
