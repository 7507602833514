import React ,{useState ,useContext} from 'react'
import ProductsContext from '../providers/ProductProvider'

import { FormattedMessage } from 'react-intl';

import { HOST_NAME } from '../config'
// import { useNavigate } from 'react-router-dom'
import Slider from 'react-slick';
import '../assets/styles/hero-styles.css'

import 'slick-carousel/slick/slick.css'; // Import Slick CSS
import 'slick-carousel/slick/slick-theme.css'; // Import default theme (optional)

const Hero=({setSearched})=>{

    const productsContext = useContext(ProductsContext)

    // const navigate = useNavigate()
    const [search ,setSearch] = useState('')
    const [loading ,setLoading] = useState(false)

    const submitSearch=async(e)=>{
        if(e){
            e.preventDefault()
        }
        let url
        if(search.length == 0){
            url = `${HOST_NAME}/api/item/get-items`
        }else{
            url = `${HOST_NAME}/api/item/get-item-by-name/${search}`
        }
        try{
            setLoading(true)
            const response = await fetch(url)
            // console.log(response)
            const data = await response.json()
            if(response.ok){
                // console.log("In hero " ,data)
                productsContext.setProducts(data.items)
                setSearched(prev => !prev)
                let target = document.querySelector('#items')
                let btn = document.querySelector('.search-btn')
                if(btn){
                    btn.focus()
                }
                if(target){
                    target.scrollIntoView({behavior:'smooth'})
                }
            }else{
            }
        }
        catch(e){
            // console.log(e)
        }
        finally{
            setLoading(false)
        }
    
    }

    const slides = [
        // {
        //   id: 2,
        //   img: require('../assets/images/bg2.jpg'),
        // },
        {
          id: 1,
          img: require('../assets/images/bg1.jpg'),
        },
        {
            id: 3,
            img: require('../assets/images/bg3.jpg'),
        },
        {
            id:4,
            img: require('../assets/images/bg4.jpg'),
        }
      ];

      const settings = {
        dots: false, // Show navigation dots
        infinite: true, // Enable continuous looping
        slidesToShow: 1, // Number of slides to show at once
        slidesToScroll: 1, // Number of slides to scroll per swipe/click
        autoplay: true, // Enable automatic slide transition
        autoplaySpeed: 5000, 
        fade:true
    }
    
    
    
    return(
        <React.Fragment>
        <div style={{ marginTop:'-10px' ,border:'solid 1px rgba(0,0,0,0.2)' ,width:'100%' ,margin:'auto' ,overflow:'hidden'}}>

                    <div style={{position:'absolute' ,zIndex:1,color:'white' , border:'solid 0px green',left:'50%',
                    transform:'translateX(-50%)', paddingTop:'50px'}}>

                        <h3 style={{textAlign:'center' ,fontWeight:'100' ,marginBottom:'15px' }}>
                                <FormattedMessage id="hero-1" /> <br/>
                            <span style={{fontWeight:'400'}}><FormattedMessage id="hero-2" /> </span> &   
                            <span style={{fontWeight:'400'}}> <FormattedMessage id="hero-3" /> </span>
                            {/* <span style={{fontWeight:'400'}}> Secure Online Payment</span> */}

                        </h3>
                        <div className='header-middle' title='Search'>

                            <form onSubmit={(e)=>!loading ? submitSearch(e):''}>
                                <input 
                                    type="text" 
                                    name="search"
                                    enterKeyHint='done'
                                    inputMode='text'
                                    className='header-search-bar'
                                    placeholder='Search for an item '
                                    onChange={(e)=>setSearch(e.target.value)}
                                />
                                <span className='search-btn' onClick={()=>!loading ? submitSearch():''}>
                                    {
                                        loading ? 
                                        <span>...</span>:
                                        <span className='fas fa-search'></span>   

                                    }
                                </span>
                            </form>
                            <span style={{color:'white' ,textAlign:'center' ,fontSize:'x-large',fontFamily:'arial'}}><FormattedMessage id="hero-4" /></span>

                        </div>

                    </div>

            <Slider {...settings} style={{height:'fit-content'}}>
            {slides.map((slide) => (
                <>
                 <div className={'hero'+slide.id} key={slide.id} style={{border:'solid 0px red'}}></div>
                </>
            ))}
            </Slider>

            </div>
        </React.Fragment>
    )
}

export default Hero

{/* <div className='hero'>
<h3 style={{textAlign:'center' ,fontWeight:'275' ,marginBottom:'15px'}}>
        The place to purchase your goods. <br/>
    <span style={{fontWeight:'400'}}> Fidelity</span> and  
    <span style={{fontWeight:'400'}}> Refundability</span>
</h3>
<div className='header-middle' title='Search'>

    <form onSubmit={(e)=>!loading ? submitSearch(e):''}>
        <input 
            type="text" 
            name="search"
            enterKeyHint='done'
            inputMode='text'
            className='header-search-bar'
            placeholder='Search for an item '
            onChange={(e)=>setSearch(e.target.value)}
        />
        <span className='search-btn' onClick={()=>!loading ? submitSearch():''}>
            {
                loading ? 
                <span>...</span>:
                <span className='fas fa-search'></span>   

            }
        </span>
    </form>
    <span style={{color:'white' ,textAlign:'center' ,fontWeight:'bold'}}>Closer to you than you think</span>

</div>
</div> */}
