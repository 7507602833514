import React,{useLayoutEffect} from 'react'

import Header from '../Header'
import Footer from '../Footer'

const MapLocation =()=>{

    useLayoutEffect(()=>{
        window.scrollTo({top:0})
    },[0])

    return(
        <React.Fragment>
            <Header/>
            <div style={{marginBottom:'40px'}}>.</div>

            <div className='main-container' style={{padding:'10px 20px'}} >
                Map location
            </div>

            <Footer/>
        </React.Fragment>
    )
}

export default MapLocation