import React ,{useState ,useEffect ,useMemo ,useRef} from 'react'
import {HOST_NAME} from '../config'
import { useNavigate } from 'react-router-dom'
import {
    MDBModalBody,MDBModalContent,MDBModalTitle, MDBBtnGroup, MDBBtn, MDBModalHeader ,
    MDBModal ,MDBModalFooter
} from 'mdb-react-ui-kit'


const ProductItem=({item ,owner ,index ,deleteItem ,deleting ,deleteError ,view})=>{

    const navigate = useNavigate()

    const [deleteModal ,setDeleteModal] = useState(false)


const openDetail=()=>{
    if(!owner){
        if(!view){
            // console.log("before navigating")
                // console.log(item)
            // return
            navigate("/detail" ,{state:item})
        }
    }
}
const placeholder = require('../assets/images/placeholder-image.png')

// console.log(((new Date()).getDay() + 14) % 7 )
// console.log("the product ")
let shortName 
if(item.name.length > 20){
    shortName = item.name.slice(0 ,17) + "..."
}else{shortName = item.name}

    return(
        <React.Fragment>

            <MDBModal 
                open={deleteModal }
                onClose={()=>setDeleteModal(false)}
                
            >
            <MDBModalContent style={{top:'20%' ,width:'300px' ,margin:'auto'}}>
                <MDBModalHeader>
                    <MDBModalTitle>Delete Item <i className='fas fa-trash'></i> </MDBModalTitle>
                </MDBModalHeader>
                <MDBModalBody style={{textAlign:'center'}}>
                    Are you sure you want to <span style={{color:'red'}}>DELETE</span> <span style={{fontWeight:'bold'}}>{item.name} ?</span> 
                </MDBModalBody>
                    <span style={{textAlign:'center' ,color:'crimson'}}>{deleteError && deleteError}</span>
                <MDBModalFooter>
                    {/* <MDBBtnGroup> */}
                        <MDBBtn color="primary" onClick={()=>setDeleteModal(false)}>Cancel</MDBBtn>
                        <MDBBtn color="danger" onClick={()=>deleteItem(item)}>Delete{deleting ? '...':''}</MDBBtn>

                    {/* </MDBBtnGroup> */}
                </MDBModalFooter>

            </MDBModalContent>

            </MDBModal>

            <div style={{cursor:'pointer' ,border:""}} onClick={()=>openDetail()}>

                {((new Date()).getMonth() + 1) == item.createdAt.split("T")[0].split("-")[1] ?
                <span style={{position:'absolute' ,border:'solid 1px red' ,
                borderTopLeftRadius:'15px' , background:'red' ,color:'white',
                fontSize:'small' ,borderBottomRightRadius:'5px' ,padding:'1px'}}>new</span>
                :''
                }
{/* background: linear-gradient(to right, #f0f0f0, #e0e0e0); */}
{/* backgroundImage:`url(${placeholder})` */}
                <div style={{ backgroundImage:`url(${placeholder})`,backgroundSize:'cover' ,backgroundPosition:'center' ,width:'165px' ,height:'173px' ,borderRadius:'15px 0px 15px 0px' }}>
                    <img
                        src={ `${item.images[0]}`}
                        width={167}
                        height={175}
                        style={{objectFit:'cover' ,border:'solid 1px rgba(0,0,0,0.1)' ,borderTopLeftRadius:'15px' ,
                        borderBottomRightRadius:owner ? '0px':'15px',
                    }}
                    />
                </div>
                <div style={{display:'flex' ,flexDirection:'column' ,padding:'5px 7px' ,border:owner ? 'solid 1px rgba(0,0,0,0.1)':'' ,
                borderBottom:'0px' ,
                }}>
                    <span style={{display:'flex' ,alignItems:'center'}}>
                        <span>{shortName} </span>&nbsp;
                    {/* {
                        item.store.accountType && item.store.accountType == "admin" &&
                            <i className='fas fa-shield-alt' style={{color:'rgba(0,0,255,0.7)' }}></i>
                    }  */}
                    {/* fas fa-shield-alt fas fa-award */}
                     </span>
                    <span style={{fontSize:'large' ,color:'black' ,fontFamily:'monospace'}}>{item.price} CFA</span>
                </div>
                {
                    owner &&
                    <div style={{display:'flex' ,justifyContent:'space-around' ,border:'solid 1px rgba(0,0,0,0.1)' ,borderTop:'0px' ,paddingBottom:'5px'}}>
                        <span style={{color:'' ,cursor:'pointer'}} onClick={()=>navigate("/store/manage-product" ,{state:{item:item}})} >
                            <i className='fas fa-pen' style={{fontWeight:'bolder' ,color:'black'}}></i> Update
                        </span>
                        
                        <span style={{color:'' ,cursor:'pointer'}} onClick={()=>setDeleteModal(true)} >
                            <i className='fas fa-trash' style={{fontWeight:'bolder' ,color:'black'}}></i> Delete
                        </span>
                        
                    </div>
                }
            </div>
        </React.Fragment>
    )
}

export default ProductItem