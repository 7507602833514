import React ,{createContext ,useState} from "react";

const ServiceContext = createContext([])

export const ServiceProvider = ({children})=>{

    const [services ,setServices] = useState([])

    return <ServiceContext.Provider value={{services:services ,setServices:setServices}} >{children}</ServiceContext.Provider>
}

export default ServiceContext