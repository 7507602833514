import React ,{useState ,createContext} from 'react'


const CartContext = createContext(null)

export const CartProvider = ({children}) =>{
        const [cart ,setCart] = useState({
            items:[]
        })
    return <CartContext.Provider value={{cart:cart ,setCart:setCart}}>{children}</CartContext.Provider>
}

export default CartContext

