import React from 'react'

const Whatsapp = () =>{
    return(
        <React.Fragment>
            <a
            href={`https://chat.whatsapp.com/LgbEf1qU7yEFVb5uBW7AtX`} 
            style={{display:'flex' ,flexDirection:'column' ,alignItems:'center' ,border:'solid 0px red' ,width:'fit-content'}}>
                <img src={require('../../assets/images/whatsapp.png')} width="50px" height="50px"
                style={{
                    borderRadius:'10px',border:'solid 0px grey' ,boxShadow:'2px 2px 3px grey',
                }}
                />
                <span style={{color:'rgb(100,100,100)' ,fontSize:'small'}}>Whatsapp</span>
            </a>
        </React.Fragment>
    )
}

export default Whatsapp