import React ,{useState} from 'react'

import { HOST_NAME } from '../config'
import '../assets/styles/slider-styles.css'

const Slider=({images})=>{

  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded ,setLoaded] = useState([])

const handleNextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide === images.length - 1 ? 0 : prevSlide + 1));
  };

  const handlePrevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide === 0 ? images.length - 1 : prevSlide - 1));
  };

// console.log(images)

function changeUrl(e ,image){
setLoaded([...loaded ,image])
}
    return(
        <React.Fragment>

    <div className="slider-container" >
      <div className="slider-wrapper">
        {images.map((image, index) => {
          let src = require('../assets/images/placeholder-image.png');
          for(let i=0;i<loaded.length;i++){
            if(loaded[i] == image && image != "false"){
              src = image;
              break;
            }
          }
          
          return(
            <>
             <img  src={src} 
             style={{height:'450px' ,position:'absolute' ,zIndex:'0' ,filter:'blur(40px)' ,width:'100%' ,opacity:currentSlide === index ? '1':'0'}} 
             /> 
              <img
                key={index}
                // src={`${HOST_NAME}/item-image/${image}`} 
                src={`${src}`}
                alt={`Slide ${index + 1}`}
                className={currentSlide === index ? 'slide active' : 'slide'}
                // style={{border:'solid 1px red'}}
                onLoad={(e)=>changeUrl(e ,image)}
              />
             </>
            )

        }
        )}
      </div>
      {
        images.length > 1 && 
        <>
            <button style={{cursor:'pointer'}} className="slider-btn prev" onClick={handlePrevSlide}>
                <i className='fas fa-chevron-right'></i>
            </button>
            <button className="slider-btn next" onClick={handleNextSlide}>
                <i className='fas fa-chevron-left'></i>
            </button>
        </>
      }
    </div>

        </React.Fragment>
    )
}

export default Slider