import React ,{useContext, useEffect, useState ,useMemo} from 'react'
import {useNavigate ,useLocation} from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

import '../assets/styles/header-styles.css'
// import SideBar from './SideBar'
import {
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarItem,
    MDBNavbarToggler,
    MDBNavbarNav,
    MDBNavbarLink,MDBContainer,

    MDBModal ,MDBModalDialog,MDBModalFooter ,
    MDBModalBody,MDBModalContent,MDBModalTitle, MDBBtnGroup, MDBBtn, MDBModalHeader

} from 'mdb-react-ui-kit'

import routes from '../Menu-Bar-routes'
import { LanguageContext } from '../App'

const Header =({store ,counter })=>{
    
    const location = useLocation()
    const navigate = useNavigate()
    const [active ,setActive] = useState()
    const [search ,setSearch] = useState()
    const [showMenu ,setShowMenu] = useState(false)
    const [logoutModal ,setLogoutModal] = useState(false)
    const {language ,setLanguage} = useContext(LanguageContext)

useEffect(()=>{
    setActive(location.pathname)
} ,[0])

const submitSearch=async(e)=>{
    e.preventDefault()
}

function handleMenu(){
    setShowMenu(prev => !prev)
}

function openAuth(){
    navigate("/auths/login")
}

async function logoutUser(){
    localStorage.setItem("user" ,null)
    window.location.pathname = "/"
}

const menuRoutes = routes.map((route ,index) => {
    const user = JSON.parse(localStorage.getItem("user"))
    // console.log(user)

    if(user == null){
        if(route.path == "logout"){
            return
        }
    }else{
        if(route.path == "/auths/login"){
            route.path = "/store"
            route.title = "My Store"
        }
    }
    return(
        <MDBNavbarItem key={index} text={true} style={{fontWeight:route.path == active ? 'bold':''}}>
            <MDBNavbarLink onClick={()=>{
                if(route.path != 'logout'){
                    navigate(`${route.path}`)
                    // setTimeout(()=>{    
                        setShowMenu(prev => !prev)
                    // },500)
                }else{
                    setLogoutModal(true)
                }
            }}>
                <i className={route.icon}></i> &nbsp;&nbsp; {route.title} &nbsp;&nbsp;&nbsp; {active == route.path && <i className='fas fa-hand-point-left'></i>}
            </MDBNavbarLink>
        </MDBNavbarItem>
    )
})


useMemo(()=>{
    window.addEventListener('click',(e)=>{
    
        if(e.target.className == "site-title"  ){
            // console.log('the target')
        }else{
            if(showMenu){
                setShowMenu(false)
                // console.log("not the target")
            }
        }
    })

},[showMenu])

    return(
        <React.Fragment>
     
            <MDBModal 
                open={logoutModal}
                onClose={()=>setLogoutModal(false)}
                
            >
            <MDBModalContent style={{top:'20%' ,width:'300px' ,margin:'auto'}}>
                <MDBModalHeader>
                    <MDBModalTitle>Deconnection <i className='fas fa-door-open'></i> </MDBModalTitle>
                </MDBModalHeader>
                <MDBModalBody>
                    Are you sure you want to logout
                </MDBModalBody>

                <MDBModalFooter>
                    {/* <MDBBtnGroup> */}
                        <MDBBtn color="primary" onClick={()=>setLogoutModal(false)}>Cancel</MDBBtn>
                        <MDBBtn color="danger" onClick={()=>logoutUser()}>Logout</MDBBtn>

                    {/* </MDBBtnGroup> */}
                </MDBModalFooter>

            </MDBModalContent>

            </MDBModal>

            <MDBNavbar style={{zIndex:20000 ,backgroundColor:'white' }} className='top-header'>
                    <MDBNavbarToggler  >
                        
                    <MDBNavbarBrand>
                        <span 
                        onClick={()=>setShowMenu(prev => !prev)} 
                        className='site-title'>
                            <i  className={!showMenu ? 'fas fa-bars':'fas fa-close'} style={{width:'20px'}}></i>
                            {/* &nbsp;&nbsp;&nbsp; */}
                            Venamal 
                                {/* <img  onClick={()=>setShowMenu(prev => !prev)} src={require('../assets/images/venamal-title.png')} style={{height:'30px'}} /> */}

                            <span>{store && ' / '+store.name}</span>
                        </span>

                         {!store &&
                          <div className='more-option'>
                            {/* <span onClick={()=>navigate('/map-location')} >
                                <span className='fas fa-map-location-dot'></span><b>Stores Locations</b>
                            </span>| */}
                            <span onClick={()=>navigate('/contact-us')} >
                                <span className='fas fa-phone'></span><b><FormattedMessage id="contact"/></b>
                            </span>|
                            <span onClick={()=>navigate("/purchase-item")}>
                            <span className='fas fa-cart-shopping'>
                                <sup style={{fontWeight:'lighter' ,fontSize:'small' ,position:'absolute' ,marginLeft:'22px' ,marginTop:'10px'}}>{counter}</sup>
                            </span><b><FormattedMessage id="cart" /></b>
                            </span>&nbsp;| 
                            <span style={{fontSize:'small' ,fontWeight:'bold' ,borderBottom:'solid 1px grey' ,color:'grey'}}
                            onClick={()=>setLanguage(prev=> prev == 'fr' ? 'en':'fr')}>
                                Lang : {language.toUpperCase()}
                            </span>
                         </div> }
                        </MDBNavbarBrand>

                    <MDBNavbarNav 
                 
                         className='dropMenu' left={true} >
                        <MDBContainer fluid    
                        style={{
                            display:showMenu ? '':'none' ,border:'solid 0px red' ,width:'fit-content' ,position:'absolute',
                            backgroundColor:'white',left:'0px' ,borderBottom:'solid 1px rgba(0,0,0,0.2)' ,borderRight:'solid 1px rgba(0,0,0,0.2)' ,textAlign:'justify',
                            fontSize:'medium' ,marginTop:'12px',zIndex:'100000000 ',padding:'0px 20px'
                        }}>
    
                            {menuRoutes}
    
                        </MDBContainer>
                    </MDBNavbarNav>
                    </MDBNavbarToggler>

                    {/* {
                        JSON.parse(localStorage.getItem("user")) == null &&
                        <div style={{marginRight:'15px'}}>
                            <span className='auth-btn' title="Sign-in" onClick={()=>openAuth()}>
                                <i className='fas fa-user'></i> Sign-in
                            </span>
                        </div>
                    } */}

            </MDBNavbar>

        </React.Fragment>
    )
}

export default Header