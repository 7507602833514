import React from 'react'
import {
    MDBFooter ,
    MDBCardFooter,
    MDBRow,
    MDBCol,
    MDBCardHeader
} from 'mdb-react-ui-kit'
import { FormattedMessage } from 'react-intl'

import '../assets/styles/footer-styles.css'

const Footer =()=>{
    return (
        <React.Fragment>
            <div style={{borderTop:'solid 1px rgba(0,0,0,0.2)' ,margin:'auto'}} className='footer-container'>
        
                <MDBFooter className='footer'> 

                    <MDBCardFooter style={{minWidth:'250px'}} >
                        <MDBCardHeader style={{fontWeight:'bold', fontFamily:'arial' ,fontSize:'large' ,color:'white' }} > FAQs </MDBCardHeader>
                        <MDBCol className='footer-item'>
                            <ul type="square">
                                <li> <FormattedMessage id="faq-1" /><br/>&nbsp;&nbsp;{'> '}[ <FormattedMessage id="res-1" /> ]</li>
                                <li> <FormattedMessage id="faq-2" /><br/>&nbsp;&nbsp;{'> '}[ <FormattedMessage id="res-2" /> ]</li>
                                <li> <FormattedMessage id="faq-3" /><br/>&nbsp;&nbsp;{'> '}[ <FormattedMessage id="res-3" /> ]</li>

                            </ul>

                        </MDBCol>
                    </MDBCardFooter>

                    <MDBCardFooter  style={{minWidth:'250px'}}>
                    <MDBCardHeader style={{fontWeight:'bold', fontFamily:'arial' ,fontSize:'large' ,color:'white'}}> <FormattedMessage id="why-venamal" /> </MDBCardHeader>

                        <MDBCol className='footer-item'>
                            <ul type="square">
                                <li> <FormattedMessage id="why-1" /> &nbsp;<i className='fas fa-credit-card'></i> </li>
                                <li> <FormattedMessage id="why-2" /> &nbsp;<i className='fas fa-phone'></i> </li>
                                <li> <FormattedMessage id="why-3" /> &nbsp;<i className='fas fa-road'></i> </li>
                                <li> <FormattedMessage id="why-4" /> &nbsp;<i className='fas fa-dollar'></i> </li>

                            </ul>

                        </MDBCol>
                    </MDBCardFooter>


                    <MDBCardFooter style={{minWidth:'250px'}} >
                    <MDBCardHeader style={{fontWeight:'bold', fontFamily:'arial' ,fontSize:'large' ,color:'white'}}> <FormattedMessage id="partners" /> </MDBCardHeader>

                        <MDBCol className='footer-item'>
                            <ul>
                                <li>CamPay - TAKWID GROUP</li>
                                <li>Itech-237</li>
                                <li>Linda Shop</li>
                                <li>Est-tech sarl</li>

                            </ul>

                        </MDBCol>
                    </MDBCardFooter>


                    <MDBCardFooter  style={{minWidth:'250px'}} >
                    <MDBCardHeader style={{fontWeight:'bold', fontFamily:'arial' ,fontSize:'large' ,color:'white'}}> <FormattedMessage id='contact-us' /> </MDBCardHeader>

                        <MDBCol className='footer-item'>
                            <ul type="square">
                                <li > <a href="mailto:venamal.contact@gmail.com"> Email &nbsp;<i className='fas fa-envelope'></i> </a> </li>
                                <li> <a href="https://chat.whatsapp.com/LgbEf1qU7yEFVb5uBW7AtX">Whatsapp &nbsp;<i className='fab fa-whatsapp'></i> </a> </li>
                                {/* <li> Twitter &nbsp;<i className='fab fa-twitter'></i> </li> */}

                            </ul>

                        </MDBCol>   
                    </MDBCardFooter>



                    <MDBCardFooter  style={{minWidth:'250px'}} >
                    <MDBCardHeader style={{fontWeight:'bold' ,fontFamily:'arial' ,fontSize:'large' ,color:'white'}}> <FormattedMessage id="terms" /> </MDBCardHeader>

                        <MDBCol className='footer-item'>
                            <ul type="square">
                                <li> <FormattedMessage id="term-1" /></li>
                                <li> <FormattedMessage id="term-2" /></li>

                            </ul>

                        </MDBCol>
                    </MDBCardFooter>
                        <br/>
                </MDBFooter>

            </div>

            <div style={{display:'flex' ,justifyContent:'center' ,padding:'5px' ,backgroundColor:'rgb(60,60,60)' ,width:'100%' ,color:'white'}}>
                &copy;2024 Venamal
            </div>

        </React.Fragment>
    )
}

export default Footer