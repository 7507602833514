import React ,{useState ,useLayoutEffect ,useContext ,useEffect } from 'react'
import { Outlet ,useNavigate ,useOutletContext ,useLocation } from 'react-router-dom'
import {
    MDBBtn ,MDBBtnGroup ,MDBModal ,MDBModalBody ,MDBModalContent ,
    MDBModalFooter ,MDBModalHeader ,MDBModalTitle ,MDBInput ,MDBInputGroup
} from 'mdb-react-ui-kit'
import { HOST_NAME } from '../../config'
import ProductItem from '../ProductItem'

import '../../assets/styles/product-styles.css'

import ProductsContext from '../../providers/ProductProvider'

const Dashboard = () =>{

    const productsContext = useContext(ProductsContext)

    const user = useOutletContext()
    const navigate = useNavigate()
    const location = useLocation()

    const [items ,setItems] = useState([])
    const [deleting ,setDeleting] = useState(false)
    const [deleteError ,setDeleteError] = useState('')

    const [error ,setError] = useState('')
    const [loading ,setLoading] = useState(false)

    const [upgradeModal ,setUpgradeModal] = useState(false)
    const [upgradeError ,setUpgradeError] = useState('')

    const [capacity ,setCapacity] = useState()
    const [phone ,setPhone] = useState('')

    const [transactions ,setTransactions ] = useState([])
// console.log(user.store)
    async function getItems(){
        setError('')
        setLoading(true)
        try{
            const response = await fetch(`${HOST_NAME}/api/store/get-store-items/${user.store._id}`)
            const result = await response.json()
            // console.log(result)
            if(response.ok){
                setItems(result.store.items)
                productsContext.setStoreProducts(result.store.items)
            }else{
                if(result.error){
                    setError(result.error)
                }
            }
        }
        catch(e){
            // console.log(e)
            setError("Please verify your internet connection")
        }
        finally{
            setLoading(false)
        }
    }
useLayoutEffect(()=>{
    if(productsContext.storeProducts.length == 0){
        // console.log("store product empty")
        getItems()
    }else{
        // console.log("store product not empty")
        setItems(productsContext.storeProducts)
    }
},[0])

// To get the current or pending transactions
useEffect(()=>{
    async function getTransaction(){
        try{
            const response = await fetch(`${HOST_NAME}/api/transaction/get-transaction` ,{
                headers:{
                    'authorization':`Bearer ${user.token}`
                }
            })
            const data = await response.json()
            // console.log(data)
            if(response.ok){
                setTransactions(data.transactions)
            }else{
                // console.log(data.error)
            }
        }
        catch(e){
            // console.log(e)
        }
    }
    // getTransaction()
} ,[0])


useEffect(()=>{
    if(location.state == 'fetch'){
        // console.log("refreshing")
        getItems()
    }
},[location.state])

let products
if(items && items.length != 0){
    products = items.map((item ,index) => {
        return <ProductItem item={item} key={item._id} owner={true} deleteItem={deleteItem} deleting={deleting} deleteError={deleteError}/>
    })
}

// let trans
// if(transactions.length > 0){
//     trans = transactions.map(tran => {
//         return (
//             <div style={{border:'solid 1px rgba(0,0,0,0.2)' ,backgroundColor:'white' ,padding:'5px' ,display:'flex' ,justifyContent:'space-between'}}>
//                 <div>
//                     <div style={{display:'flex' ,flexDirection:'column'}}>
//                     {tran.items.map(order => {
                        
//                         return (
//                             <>
//                                 <span> {order.item.name} [ {order.item.price} X {order.quantity} = {order.quantity * order.item.price} ] </span>
//                             </>
//                         )
//                     })}
//                     <span style={{fontWeight:'bold'}}>Total : {tran.totalPrice} CFA </span>
//                     </div>
//                 </div>
//                 <div>
//                     <span>{tran.email}</span>

//                 </div>
//                 <div style={{display:'flex' ,flexDirection:'column'}}>
//                     <span>{tran.createdAt.split("T")[0]}</span>
//                     <span>{tran.createdAt.split("T")[1]}</span>
//                 </div>

//                 <span style={{padding:'2px 5px' ,border:'solid 1px rgba(0,0,0,0.2)' ,height:'fit-content'}}>Clear</span>

//             </div>
//         )
//     })
// }


async function deleteItem(item){
    try{
        // console.log(item)
        // return 
        let {_id } = item
        setDeleting(true)
        setDeleteError("")
        const response = await fetch(`${HOST_NAME}/api/item/delete-item` ,{
            method:"delete",
            headers:{
                'content-type':'application/json',
                'authorization':`Bearer ${user.token}`
            },
            body:JSON.stringify({id:_id ,storeId:user.store._id})
        }) 
        const data = await response.json()
        if(response.ok){
            setItems(prev => {
                let temp = prev.filter(item => item._id != _id)
                productsContext.setStoreProducts(temp)
                return temp
            })
        } else{
            setDeleteError(data.error)
        }
    }
    catch(e){
        // console.log(e)
        setDeleteError("Please verify your internet")
    }
    finally{
        setDeleting(false)
    }
}
// console.log(products)


async function submitUpgrade(){
    if(!capacity){
        setUpgradeError("Please enter a number")
        return
    }
    if(capacity%5 != 0){
        setUpgradeError("Please enter a multiple of 5")
        return
    }
    setTimeout(()=>{
        setUpgradeError('ok')
    } ,500)
    return
}
// console.log(user)

    return(
        <React.Fragment>

            <MDBModal 
                open={upgradeModal }
                onClose={()=>setUpgradeModal(false)}
            >
            <MDBModalContent style={{top:'20%' ,width:'300px' ,margin:'auto'}}>
                <MDBModalHeader>
                    <MDBModalTitle>Upgrade Store Capacity <i className='fas fa-thunderstorm'></i> </MDBModalTitle>
                </MDBModalHeader>
                <MDBModalBody style={{textAlign:'center'}}>
                    Upgrading your store's <span style={{fontWeight:'bold'}}>storage capacity</span> (number of items)<br/><br/>

                    <MDBInputGroup>
                        <MDBInputGroup className='form-group'>
                            <span>Capacity <span style={{color:'darkgreen'}}>(free &lt;6)</span></span>
                            <MDBInput 
                                type='number' 
                                value={capacity} 
                                onChange={e=>setCapacity(e.target.value)} 
                                min={5}
                                step={5}
                            />
                        </MDBInputGroup>

                        <MDBInputGroup className='form-group'>
                         <span>Rent (monthly)</span>
                            <MDBInput type='number' disabled value={capacity >= 10 ? 50*(capacity-5):0}/>
                        </MDBInputGroup>
                    </MDBInputGroup>

                    <MDBInputGroup className='form-group'>
                         <span>Phone Number (OM | MOMO)</span>
                            <MDBInput type='number' value={phone} onChange={(e)=>setPhone(e.target.value)}/>
                        </MDBInputGroup>

                </MDBModalBody>
                    <span style={{textAlign:'center' ,color:'crimson'}}>{upgradeError && upgradeError=='ok' ?
                    <span style={{color:'gray'}}>" Unavailable 😥 try later ".</span>
                    :upgradeError}</span>
                <MDBModalFooter>
                    {/* <MDBBtnGroup> */}
                        <MDBBtn color="primary" onClick={()=>setUpgradeModal(false)}>Cancel</MDBBtn>
                        <MDBBtn color="success" onClick={()=>{submitUpgrade()}}>Upgrade</MDBBtn>

                    {/* </MDBBtnGroup> */}
                </MDBModalFooter>

            </MDBModalContent>

            </MDBModal>

            <div style={{display:'flex' ,flexDirection:'column' ,padding:'30px 0px 30px 0px' ,gap:'30px' ,border:'solid 0px green'}}>

               { 
               
                 user.store.accountType != "admin" && 

                <div style={{display:'flex' ,justifyContent:'space-around'  ,fontWeight:'bold'  ,paddingBottom:'20px' ,
                    borderBottom:'solid 1px rgba(0,0,0,0.2)' ,alignItems:'center'}}>
                        <span title="status"> Status:<span style={{color:user.store.status == "active" ? 'darkgreen':'darkred'}}>
                            {user.store.status == "active" ? 'active':'in-active'}
                        </span></span>

                        <span title="maximum capacity">Capacity:{user.store.capacity}</span>

                        <span title="upgrade version" 
                        style={{
                            cursor:'pointer' ,border:'solid 1px grey' ,padding:'5px' ,borderRadius:'5px',
                            background:'linear-gradient(red ,black)' ,color:'white'
                        }} 
                        onClick={()=>{setUpgradeModal(true)}}> Upgrade  <i className='fas fa-thunderstorm'></i></span>

                    </div>
                 } 

                <div style={{display:'flex' ,justifyContent:'space-around'  ,border:'solid 0px red' ,flexWrap:'wrap' ,gap:'5px' ,alignItems:'flex-start'}}>

                    {/* <div>
                        <MDBBtnGroup onClick={()=>navigate("/store/update-store" ,{state:{store:user.store}})}>
                            <MDBBtn color='primary'>
                                <span>Update Store</span>&nbsp;
                                <i className='fas fa-pen'></i>
                            </MDBBtn>
                        </MDBBtnGroup>
                    </div> */}

                {
                    user.store.accountType == "admin" &&
                    <div>
                        <MDBBtnGroup onClick={()=>navigate("/store/transactions")}>
                            <MDBBtn color='danger'>
                                <span>Transactions</span>&nbsp;
                                <i className='fas fa-exclamation'></i>
                            </MDBBtn>
                        </MDBBtnGroup>
                    </div>
                }

                    <div>
                        <MDBBtnGroup onClick={()=>navigate("/store/manage-product")}>
                            <MDBBtn color='success'>
                                <span>Add Item</span>&nbsp;
                                <i className='fas fa-add'></i>
                            </MDBBtn>
                        </MDBBtnGroup>
                    </div>

                    {/* <div>
                        <MDBBtnGroup onClick={()=>navigate("/store/manage-service")}>
                            <MDBBtn color='success'>
                                <span>Add Service</span>&nbsp;
                                <i className='fas fa-add'></i>
                            </MDBBtn>
                        </MDBBtnGroup>
                    </div> */}

                {/* {
                    user.store.accountType == "admin" &&
                    <div>
                        <MDBBtnGroup onClick={()=>navigate("/store/view-messages")}>
                            <MDBBtn color='dark'>
                                <span>View Messages</span>&nbsp;
                                <i className='fas fa-eye'></i>
                            </MDBBtn>
                        </MDBBtnGroup>
                    </div>
                } */}

                </div>

                

                    {/* Items */}
                <div>
                    <h4 style={{padding:'0px 10px'}}>Items ({items.length}) </h4>

                    <div className='product-container' style={{border:'solid 0px red' ,rowGap:'20px'}}>

                    {loading && <span style={{fontSize:'large' ,letterSpacing:'1px'}}>Loading products ...</span>}
                    <div style={{fontSize:'large' ,letterSpacing:'1px' ,color:'crimson' ,textAlign:'center'}}>{error && error}</div>
                        {products}

                    </div>

                </div>

            </div>


        </React.Fragment>
    )
}

export default Dashboard