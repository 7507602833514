import React ,{useLayoutEffect, useState} from "react";
import {  useNavigate,useLocation  ,useOutletContext} from "react-router-dom";
import {
        MDBInput ,MDBInputGroup ,MDBBtnGroup ,MDBBtn ,MDBFile,MDBTextArea,
        MDBDropdown ,MDBDropdownItem ,MDBDropdownMenu ,MDBDropdownToggle, MDBRadio
} from 'mdb-react-ui-kit'

import '../../assets/styles/auths-styles.css'
import { HOST_NAME } from "../../config";

const ManageProduct = () => {

    const navigate = useNavigate()
    const location = useLocation()
    const user = useOutletContext()

    const [error ,setError] = useState('')
    const [loading ,setLoading] = useState(false)
    const [files ,setFiles] = useState([])
    const [images ,setImages] = useState([])
    const [data ,setData] = useState({
        name:'',
        price:'',
        description:'',
        quantity:'',
        category:'',
        fastDeal:false,
        duration:5
    })
    const [title ,setTitle] = useState("Add")

useLayoutEffect(()=>{
    if(location.state && location.state.item){
        setTitle("Update")
        setData(location.state.item)
    }
},[0])

    async function handleImageChange(e){
        setFiles([])
        setImages([])
        let temp = e.target.files
        // console.log(e.target.files)   
        setFiles(e.target.files)

        for(let i=0;i<temp.length;i++){
            const reader = new FileReader()
             reader.onload=async()=>{
                setImages(prev => [...prev ,reader.result])
                // console.log(images)
                // if(images.length == 0){
                //     // console.log(reader.result)
                //     setImages([reader.result])
                // }else{
                //     setImages(prev => [...prev ,reader.result])
                // }
            }
            reader.readAsDataURL(temp[i])
        }

        // console.log(images)

    }

    function cancel(){
        setImages([])
        setFiles([])
        setData({
            name:'',
            price:'',
            description:''
        })
        navigate("/store")
    }


const submitForm=async()=>{
    let {name ,price ,description ,category ,quantity ,fastDeal ,duration} = data
    // console.log(data)
    // return
    if(!name || !price ||category==null){
        setError("Please fill the require fields")
        return
    }
    setLoading(true)
    setError('')

    const formData = new FormData()
    for(let i=0;i<files.length;i++){
        formData.append("files" ,files[i])
    }
    formData.append("name" ,name)
    formData.append("price" ,price)

    formData.append("description" ,description)
    formData.append("category" ,category)
    formData.append("quantity" ,quantity)

    formData.append("fastDeal" ,fastDeal)
    formData.append("duration" ,duration)

    formData.append("store" ,user.store._id)
    
    let body = {
        name:name ,
        price:price,
        description:description,
        category:category,
        quantity:quantity,
        id:data._id,
        fastDeal:fastDeal,
        duration:duration
    }

    // console.log(body)
    // return
    try{
        let url = `${HOST_NAME}/api/item/create-item`
        if(title == "Update"){
            url = `${HOST_NAME}/api/item/update-item`
        }

            let response
        if(title == "Update"){
             response = await fetch(url ,{
                method:  "put",
                headers:{
                    'content-type':'application/json',
                    'authorization':`Bearer ${user.token}`
                },
                body: JSON.stringify(body)
            })
        }else{
             response = await fetch(url ,{
                method: "post",
                headers:{
                    // 'content-type':'application/json',
                    'authorization':`Bearer ${user.token}`
                },
                body:formData
            })
        }

        const result = await response.json()
        if(response.ok){
            // console.log(result)
            navigate("/store" ,{state:'fetch'})
        }else{
            setError(result.error)
        }
    }
    catch(e){
        // console.log(e)
        setError("An error occured")
    }
    finally{
        setLoading(false)
    }
}
// console.log("images",images.length)
// console.log("files" ,files.length)
// console.log(data.fastDeal)
    return(
        <React.Fragment>

            <div className="auth-container" style={{marginTop:'15px'}}>
                <h3> <span style={{fontSize:'small' ,fontWeight:'bold' ,cursor:'pointer'}} onClick={()=>navigate("/store")}><i className='fas fa-arrow-left'></i> back <br/> </span>
                 {title} Item</h3>
                <div className="form">

                    <MDBInputGroup className="form-group">
                        <span>Name</span>
                        <MDBInput 
                            value={data.name}
                            type="text"
                            onChange={(e)=>setData({...data ,name:e.target.value})}
                        />
                    </MDBInputGroup>

                    <MDBInputGroup>

                        <MDBInputGroup className="form-group">
                            <span>Price (in CFA)</span>
                            <MDBInput 
                                value={data.price}
                                type="number"
                                onChange={(e)=>setData({...data ,price:e.target.value})}
                            />
                        </MDBInputGroup>

                        <MDBInputGroup className="form-group">
                            <span>Quantity (optional)</span>
                            <MDBInput 
                                value={data.quantity}
                                type="number"
                                onChange={(e)=>setData({...data ,quantity:e.target.value})}
                            />
                        </MDBInputGroup>

                    </MDBInputGroup>

                    {/* Selecet */}
                    <MDBInputGroup style={{justifyContent:'space-evenly' ,alignItems:'center'}}>
                    <span>Category</span>
                    <select  style={{padding:'5px'}} onChange={(e)=>setData({...data ,category:e.target.value})} value={data.category}>
                        <option value={null} >---------</option>
                        <option value={'electronics'}>Electronics</option>
                        <option value={'dresses'}>Dresses</option>
                        <option value={'shoes'}>Shoes</option>
                        <option value={'tools'}>Tools & equipments</option>
                        {/* <option value={'services'}>Services</option> */}
                        <option value={'vehicles'}>Vehicles</option>
                        <option value={'jeweries'}>Jeweries</option>
                        <option value={'animals'}>Animals</option>
                        <option value={'other'}>Other</option>


                    </select>
                    </MDBInputGroup>

                    {/* <MDBInputGroup style={{display:'flex' ,width:'fit-content' ,gap:'25px' }}>


                        <MDBInputGroup style={{flex:1}}>
                        <span >Fast Deal <i className="fas fa-running"></i> ?&nbsp;&nbsp;&nbsp;</span>
                            <MDBRadio  name="fast" 
                            onClick={e=>{
                                setData({...data,fastDeal:!data.fastDeal})}
                            } 
                            checked={data.fastDeal}/>
                        </MDBInputGroup>

                    </MDBInputGroup> */}

                    <MDBInputGroup className="form-group">
                        <span>Description (optional)</span>
                        {/* <MDBInput 
                            value={data.description}
                            type="text"
                            multiple
                            
                            onChange={(e)=>setData({...data ,description:e.target.value})}
                        /> */}
                        <MDBTextArea
                            onChange={(e)=>setData({...data ,description:e.target.value})}
                            textarea={true}
                            value={data.description}
                            
                        />
                    </MDBInputGroup>

                    <MDBInputGroup className="form-group">
                        <span>Availability in days</span>
                        <MDBInput type='number' value={data.duration} onChange={(e)=>{setData({...data ,duration:e.target.value})}}/>
                    </MDBInputGroup>


                    {title != "Update" ? 
                    <label htmlFor="images" style={{cursor:'pointer'}}>Select image(s) <i className="fas fa-camera"></i> <span style={{fontSize:'small'}}>(click here)</span></label>
                    :''
                    }
                    <MDBFile multiple id="images"  itemType="image" style={{display:'none'}} onChange={(e)=>handleImageChange(e)}/>
                    <div style={{border:'solid 0px red',height:images.length > 0 ? '70px':'0px' ,overflowY:'hidden' ,overflowX:'auto' ,
                    display:'flex' ,gap:'5px' ,scrollbarWidth:'0px' ,width:'300px' }}>
                        {
                            images.length > 0 &&
                            images.map((image ,index) => {
                            return  <img src={image} key={index} style={{width:'70px' ,height:'70px'}}/>
                            })
                        }
                    </div>

                    <span style={{color:'crimson' ,textAlign:'center' ,fontSize:'small' }}>{ error }</span>

                    <MDBBtnGroup onClick={()=>submitForm()}>
                        <MDBBtn  color="dark" onClick={()=>cancel()} disabled={loading}>Cancel</MDBBtn>
                        <MDBBtn disabled={loading}>{title == "Update" ? "Update":'Create'} Item {loading && '...'}</MDBBtn>
                    </MDBBtnGroup>

                </div>

            </div>
        </React.Fragment>
    )
}

export default ManageProduct
