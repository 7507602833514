import React ,{useState ,useContext} from 'react'
import { HOST_NAME } from '../config'

import { FormattedMessage } from 'react-intl'

import ProductsContext from '../providers/ProductProvider'

const Category=({setSearched})=>{

    const productsContext = useContext(ProductsContext)

    const [cursor ,setCursor] = useState('')
    const [loading ,setLoading] = useState(false)
    const [category ,setCategory] = useState([
        {
            name:<FormattedMessage id="all" />,
            icon:"fas fa-v",
            query:"all"
        },
        {
            name:<FormattedMessage id="electronic" />,
            icon:"fas fa-desktop-alt",
            query:"electronics"
        },
        {
            name:<FormattedMessage id="dress" />,
            icon:"fas fa-shirt",
            query:"dresses"
        },
        {
            name:<FormattedMessage id="shoe" />,
            icon:"fas fa-shoe-prints",
            query:"shoes"
        },
        {
            name:<FormattedMessage id="tool" />,
            icon:"fas fa-tools",
            query:"tools"
        },
        // {
        //     name:"Services",
        //     icon:"fas fa-hands-helping",
        //     query:"services"
        // },
        {
            name:<FormattedMessage id="vehicle" />,
            icon:"fas fa-car",
            query:"vehicles"
        },
        {
            name:<FormattedMessage id="jewery" />,
            icon:"fas fa-magic-wand-sparkles",
            query:"jeweries"
        },
        {
            name:<FormattedMessage id="animal" />,
            icon:"fas fa-dog",
            query:"animals"
        },
        {
            name:<FormattedMessage id="other" />,
            icon:"fas fa-lightbulb",
            query:"other"
        },

    ])

const categories = category.map((cat ,index )=> {
    return <CategoryItem item={cat} key={index} search={searchByCategory} active={cursor} loading={loading}/>
})

async function searchByCategory(category){
    // console.log(category)
    let url
    if(category != 'all'){
        setCursor(category)
        url = `${HOST_NAME}/api/item/get-item-by-category/${category}`
    }else{
        setCursor('')
        url = `${HOST_NAME}/api/item/get-items`
    }

    setLoading(true)

    try{

        const response = await fetch(url)
        const data = await response.json()
        // console.log(data)
        if(response.ok){
            productsContext.setProducts(data.items)
            setSearched(prev => !prev)
        }else{
            setCursor('')
        }
    }
    catch(e){
        // console.log(e)
        setCursor('')
    }
    finally{
        setLoading(false)
    }

}

    return (
        <React.Fragment>
            <div>
            <span style={{paddingLeft:'10px' ,marginBottom:'15px' ,fontSize:'x-large'  ,fontFamily:'arial'}}>Categories </span>
            <div style={{display:'flex' ,gap:'18px' ,margin:'10px' ,flexWrap:'wrap' ,justifyContent:'center' ,border:'solid 0px red' ,
            maxHeight:'250px' ,overflowY:'auto' ,overflowX:'auto' ,scrollbarWidth:'none' }}>
                {categories}
            </div>

            </div>
        </React.Fragment>
    )
}

export default Category

const CategoryItem=({item ,search ,active ,loading})=>{
    return (
        <div style={{
            border:'solid 1px grey' ,display:'flex' ,flexDirection:'column',
            width:'100px' ,height:'100px' ,justifyContent:'center' ,alignItems:'center',
            textAlign:'center' ,cursor:'pointer' ,fontSize:'15px',
            backgroundColor:active==item.query ? "grey":'',
            color:active == item.query?'white':'',
        }}
            onClick={()=>{
                if(active ==item.query){
                    search('all')
                }else{
                    search(item.query)
                }
            }}
        >
            <i className={item.icon}> {active == item.query && loading ? '...':''} </i>
            <span>{item.name}</span>
        </div>
    )
}